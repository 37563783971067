// depois pensar uma maneira de colocar divs dentro de li sem quebrar o layout. o elemento "li" por padrão não deve possuir atributo tabIndex
// (deixei comentado porque pretendo mexer nisso o mais breve possível)
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import PageClean from '../default-v2/PageClean';
import { documentsIAmResponsibleFor } from '../../actions/ActionDocumentPending';
import MyDocumentComponent from './MyDocumentComponent';
import ButtonBack from '../../components/ButtonBack';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import ScrollBar from '../../components/ScrollBar';
import { sendToTrashBin } from '../../actions/ActionDocumentEditor';
import Document from '../document/Document';
import { oLocalStorage } from '../../config/EncodedLocalStorage';

class MyDocument extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      aResponsibleDocuments: [],
      bLoading: true,
      isSubmitting: false
    };
  }

  componentDidMount() {
    const { setAlert, bIsMyAccountPage } = this.props;
    if (bIsMyAccountPage) {
      documentsIAmResponsibleFor()
        .then(aResponsibleDocuments => this.setState({ bLoading: false, aResponsibleDocuments }))
        .catch(oError => {
          this.setState({ bLoading: false });
          setAlert('error', oError.aMsgErrors);
        });
    }
  }

  openRoute = (cRoute, nVersionId) => {
    const { history } = this.props;
    if (cRoute === 'version.show') history.push(`/document/detail/${nVersionId}`);
    else if (cRoute === 'version.edit') history.push(`/document/editor/${nVersionId}`);
    else if (cRoute === 'version.review') history.push(`/document/review/${nVersionId}`);
    else if (cRoute === 'version.publish') history.push(`/document/publish/${nVersionId}`);
  };

  openDocumentEditModal = ( oDocument ) => {
    const { history, setAlert } = this.props;
    this.setState({
      rcmpAlert: (
        <SweetAlert
          customClass="modal-edit modal-edit-v2 lg scrollBar"
          showConfirm={false}
          onConfirm={this.hideAlert}
          onCancel={this.hideAlert}
          title={null}
        >
          <div className="head-modal">
            {intl.get('DocumentDetails.editar_documento')}
            <span
              role="button"
              tabIndex="0"
              onClick={this.hideAlert}
              className="close"
            />
          </div>
          <ScrollBar>
            <Document
              bNewDocument={false}
              nVersionId={oDocument.ver_nid}
              onSuccess={async (cMsg) => {
                // atualiza a página quando altera um documento, mudar no futuro para atualizar a lista dinamicamente
                history.push({
                  state: { aAlertMessages: { cType: 'success', cMsg } }
                });
              }}
              onError={(cMsg) => setAlert('error', cMsg)}
              closeModal={this.hideAlert}
            />
          </ScrollBar>
        </SweetAlert>
      )
    });
  }

  decideOpenRoute = oItem => {
    let cKey = '';
    switch (oItem.stat_cslugname) {
      case 'in-writing':
        cKey = 'document_writers';
        break;
      case 'in-review':
        cKey = 'document_reviewers';
        break;
      case 'awaiting-publish':
        cKey = 'document_responsibles';
        break;
      default:
        return false;
    }

    if (Number(oItem.user_nid) === Number(oLocalStorage.get('nUserId'))) {
      this.openRoute(oItem.cRoute, oItem.ver_nid);
    } else {
      this.openModalIconActionDocument(null, oItem.user_cname, oItem.stat_cslugname, oItem.cDataDiff);
    }
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
    this.componentDidMount();
  };

  openModalIconActionDocument = (evt, oDocument, cVersionStatus, aDataDiff) => {
    if (evt !== null) {
      evt.stopPropagation();
    }
    let cData = '';
    let cStatus = '';
    let cUserName = '';

    if (cVersionStatus === 'in-writing') {
      cStatus = intl.get(`Status.edition`);
      cUserName = oDocument.user_cname;
    } else if (cVersionStatus === 'in-review') {
      cStatus = intl.get(`Status.review`);
      cUserName = oDocument.user_cname;
    } else {
      cStatus = intl.get(`Status.publication`);
      cUserName = oDocument.user_cname;
    }

    if (aDataDiff.bDataExpire == 1) {
      cData = '. ' + intl.get('Document.deadline_expired') + ' ' + aDataDiff.cDataDiff + '.';
    } else {
      cData = ' ' + intl.get('Document.until') + ' ' + aDataDiff.cDataDiff + '.';
    }

    this.setState({
      rcmpAlert: (
        <SweetAlert
          showCancel
          showConfirm={false}
          closeOnClickOutside={true}
          cancelBtnText={intl.get('cancelar')}
          onCancel={this.hideAlert}
          title=""
          customClass="modal-edit md disabled-overflow"
        >
          <div className="head-modal">
            {intl.get('Document.document_information')}
            <span
              aria-labelledby="head-modal"
              tabIndex={0}
              onClick={this.hideAlert}
              className="close"
              role="button"
              onKeyPress={this.hideAlert}
            />
          </div>
          <div>
            <p>
              {intl.get('Document.awaiting') + ' ' + cStatus + ' ' + intl.get('Document.by') + ' ' + cUserName + cData}
            </p>
          </div>
        </SweetAlert>
      )
    });
  };

  deleteDocumentDashboard = nVersionId => {
    const { fnUpdateComponent } = this.props;
    if (this.state.isSubmitting) return; // Bloquear dedo nervoso
    this.setState({ isSubmitting: true }, () => {
      sendToTrashBin(nVersionId)
        .then(() => {
          this.setState({
            rcmpAlert: (
              <SweetAlert
                success
                title=""
                onConfirm={() => {
                  this.hideAlert();
                  if (fnUpdateComponent) fnUpdateComponent();
                }}
              >
                {intl.get('DocumentEditor.mensagem_removido')}
              </SweetAlert>
            ),
            isSubmitting: false
          });
        })
        .catch(oError => {
          this.setState({
            rcmpAlert: (
              <SweetAlert error title="" onConfirm={this.hideAlert}>
                {oError.aMsgErrors}
              </SweetAlert>
            ),
            isSubmitting: false
          });
        });
    });
  };

  openDeleteModalDocument = (evt, oDocument, oVersion) => {
    evt.stopPropagation();
    this.setState({
      rcmpAlert: (
        <SweetAlert
          title={intl.get('lixeira')}
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancelar')}
          cancelBtnBsStyle="danger"
          showCancel
          onConfirm={evt => {
            this.deleteDocumentDashboard(oVersion.ver_nid);
          }}
          onCancel={this.hideAlert}
          confirmBtnCssClass={this.state.isSubmitting ? 'disabled-btn' : ''}
        >
          {intl.get('mensagem_excluir', { tipo: intl.get('o_documento'), nome: oDocument.doc_ctitle })}
        </SweetAlert>
      )
    });
  };

  successEditionDocument = cMsg => {
    const { fnUpdateComponent } = this.props;
    this.setState({
      rcmpAlert: (
        <SweetAlert
          success
          title=""
          onConfirm={() => {
            this.hideAlert();
            if (fnUpdateComponent) fnUpdateComponent();
          }}
        >
          {cMsg}
        </SweetAlert>
      )
    });
  };

  errorEditionDocument = cMsg => {
    this.setState({
      rcmpAlert: (
        <SweetAlert
          error
          title=""
          onConfirm={() => {
            this.hideAlert();
          }}
        >
          {cMsg}
        </SweetAlert>
      )
    });
  };

  render() {
    const { bLoading, aResponsibleDocuments, rcmpAlert } = this.state;
    const { bIsMyAccountPage, aPendingEditsResponsible } = this.props;
    if (bIsMyAccountPage != null && bIsMyAccountPage != undefined && !bIsMyAccountPage) {
      return (
        <>
          <MyDocumentComponent
            aResponsibleDocuments={aPendingEditsResponsible}
            onClick={this.decideOpenRoute}
            onClickIcon={this.openModalIconActionDocument}
            fnDeleteModal={this.openDeleteModalDocument}
            fnEditModal={this.openDocumentEditModal}
            bIsMyAccountPage={false}
          />
          {rcmpAlert}
        </>
      );
    }

    return (
      <PageClean loading={bLoading ? 1 : 0} id="dashboard-wrapper">
        {rcmpAlert}
        <h3 title={intl.get('MyDocument.documentos_responsavel')}>{intl.get('MyDocument.meus_documentos')}</h3>
        <div className="double">
          <div className="dashboard-block">
            <MyDocumentComponent
              aResponsibleDocuments={aResponsibleDocuments}
              onClick={this.decideOpenRoute}
              onClickIcon={this.openModalIconActionDocument}
              fnDeleteModal={this.openDeleteModalDocument}
              fnEditModal={this.decideOpenRoute}
              bIsMyAccountPage={true}
            />
          </div>
        </div>
        <div className="button-stack">
          <ButtonBack />
        </div>
      </PageClean>
    );
  }
}

MyDocument.defaultProps = {
  bIsMyAccountPage: true,
  aPendingEditsResponsible: [],
  fnUpdateComponent: null
};

export default MyDocument;
