import _ from 'lodash';
import moment from 'moment';
import localeEng from 'moment/locale/en-gb';
import localePtBr from 'moment/locale/pt-br';
import React, { Component, useRef } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import intl from 'react-intl-universal';
import { NavLink } from 'react-router-dom';
import { STATUS } from 'react-joyride';
import Button from '../../components/Button';
import getFolders from '../../actions/ActionFolderIndex';
import { createAndRedirect, sendToTrashBin } from '../../actions/ActionDocumentEditor';
import { getDocumentDetails } from '../../actions/ActionDocumentDetails';
import imgfLoading from '../../assets/img/preloader-black.gif';
import Breadcrumb from '../../components/Breadcrumbs';
import OutsideHandlerClick from '../../components/OutsideHandlerClick';
import Axios from '../../config/Axios';
import { can, canAtLeast, checkModule, hasModule } from '../../config/Permissions';
import Page from '../default-v2/Page';
import Folder from './Folder';
import FolderShow from './FolderShow';
import FolderMove from './FolderMove';
import DocumentMove from '../document/DocumentMove';
import RequestModal from '../request/RequestModal';
import ScrollBar from '../../components/ScrollBar';
import AltCheckbox from '../../components/AltCheckbox';
import CardHeader from '../default-v2/CardHeader';
import Filter from '../default-v2/Filter';
import NavItem from '../default-v2/NavItem';
import imgfCards from './img/icon-cards.svg';
import imgfGrid from './img/icon-grid.svg';
import imgfFolderLarge from './img/folderindex-folder-large.svg';
import imgfDocumentLarge from './img/folderindex-document-large.svg';
import imgfRequest from '../document/img/icon-comment.svg';
import Document from '../document/Document';
import { unfile } from '../../actions/ActionVersionHistory';
import './styles/folderindex.scss';
import documentImg from '../../assets/img/documents.svg';
import { oLocalStorage } from '../../config/EncodedLocalStorage';
import TreeViewLayer from '../../components/TreeViewLayer';
import FolderCard from './FolderCard';
import Chevron from '../../assets/icons/Chevron';
import { formatDateToUserTZ } from '../../utils/time';
import FolderContentsList from './FolderContentsList';
import loadBreadcrumb from '../../actions/ActionBreadcrumb';
import DocumentPreview from '../document/DocumentPreview';

const oLocales = {
  en: localeEng,
  'pt-br': localePtBr
};

const LoadingSpinner = () => (
  <div className="centered-text">
    <img alt="Carregando" src={imgfLoading} />
    <br />
    {intl.get('FolderIndex.carregando')}
  </div>
);

class FolderIndex extends Component {
  bIsMounted = false;

  constructor(oProps) {
    super(oProps);

    // const oWindowSize = useRef([window.innerWidth, window.innerHeight]);
    const oConfig = JSON.parse(localStorage.getItem('cConfig'));

    moment.locale(oLocalStorage.get('cLanguage'));

    let cViewType = '';
    if (window.innerWidth <= 600) {
      cViewType = 'grid';
    } else if (oConfig && oConfig.cViewType) {
      cViewType = oConfig.cViewType;
    } else {
      cViewType = 'list';
    }

    this.state = {
      oFolderCards: {
        pending: { count: 0, data: [] },
        review: { count: 0, data: [] },
        published: { count: 0, data: [] },
        editing: { count: 0, data: [] }
      },
      bIsShowCards: true,
      bLoading: true,
      bInternalLoad: false,
      bIsSubmitting: false,
      nFolderId: +(oProps.match.params.nFolderId ?? 1),
      aFolders: [],
      aDocuments: [],
      aFiledDocuments: [],
      aArchivedFolders: [],
      oFolder: {},
      bActualMenuFolder: false,
      aRecentDocuments: [],
      aPages: [{ cName: 'modulo_documentos', cLink: undefined }],
      oConfig: {
        cViewType,
        cOrderBy: oConfig && oConfig.cOrderBy ? oConfig.cOrderBy : 'cName',
        cType: oConfig && oConfig.cType ? oConfig.cType : 'desc',
        bShowObsolete: oConfig && oConfig.bShowObsolete ? oConfig.bShowObsolete : false
      },
      bRun: false,
      aSteps: [
        {
          rcmpContent: <h2>{intl.get('FolderIndex.funcionamento')}</h2>,
          cPlacement: 'center',
          oLocale: {
            rcmpSkip: <strong aria-label="skip">{intl.get('Dashboard.pular_intro')}</strong>,
            cNext: intl.get('proximo')
          },
          cTarget: 'body'
        },
        {
          rcmpContent: intl.get('FolderIndex.campo_busca'),
          cPlacement: 'bottom',
          oLocale: {
            rcmpSkip: <strong aria-label="skip">{intl.get('Dashboard.pular_intro')}</strong>,
            cNext: intl.get('proximo'),
            cBack: intl.get('anterior')
          },
          cTarget: '.filter-wrapper',
          cTitle: intl.get('FolderIndex.busca')
        },
        {
          cTitle: intl.get('FolderIndex.lista_pastas'),
          oLocale: {
            rcmpSkip: <strong aria-label="skip">{intl.get('Dashboard.pular_intro')}</strong>,
            cNext: intl.get('proximo'),
            cBack: intl.get('anterior')
          },
          rcmpContent: <div>{intl.get('FolderIndex.aqui_pastas')}</div>,
          cTarget: '.folder-list',
          cPlacement: 'top'
        },
        {
          cTitle: intl.get('FolderIndex.lista_arquivos'),
          oLocale: {
            rcmpSkip: <strong aria-label="skip">{intl.get('Dashboard.pular_intro')}</strong>,
            cNext: intl.get('proximo'),
            cBack: intl.get('anterior')
          },
          rcmpContent: <div>{intl.get('FolderIndex.aqui_arquivos')}</div>,
          cTarget: '.list-shadow',
          cPlacement: 'top'
        },
        {
          cTitle: intl.get('FolderIndex.botao_inteligente'),
          oLocale: { rcmpSkip: intl.get('proximo'), cBack: intl.get('anterior'), cLast: intl.get('finalizar') },
          rcmpContent: <div>{intl.get('FolderIndex.botao_inteligente_descricao')}</div>,
          cTarget: '.main-button',
          cPlacement: 'top'
        }
      ],
      nSize: 35,
      nQtylist: 0,
      bIsProcessing: false,
      bDebounceLock: false,
      cSearch: '',
      nRestoringFolderId: null
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setDocListObserverRef = this.setDocListObserverRef.bind(this);
    this.refreshDocListObserver = this.refreshDocListObserver.bind(this);
  }

  componentDidMount = () => {
    this.bIsMounted = true;
    const { nFolderId, oConfig } = this.state;
    const { history } = this.props;
    checkModule(history, 'documents');

    document.addEventListener('mousedown', this.handleClickOutside);

    if (!oConfig.bShowObsolete) {
      this.fetchFolders();
    } else {
      this.fetchArchivedFolders();
    }
    this.fetchDocumentsAccessed();
    this.fnGetCardData();
    loadBreadcrumb(nFolderId).then((aBreadcrumbFolders) => this.setState({ aBreadcrumbFolders }));
  };

  componentWillMount = () => {
    this.bIsMounted = false;
    document.removeEventListener('mousedown', this.handleClickOutside);
  };

  setWrapperRef(elNode) {
    this.wrapperRef = elNode;
  }

  setDocListObserverRef(elDocList) {
    this.oDocListObsRef = elDocList;
  }

  createDocListOberserver = () => {
    const oObserver = new IntersectionObserver(this.handleLoadMoreDocs, {
      root: null,
      rootMargin: `200px 0px 200px 0px`,
      threshold: 0,
    });
    this.oListObserver = oObserver;
    this.refreshDocListObserver();
  }

  refreshDocListObserver = () => {
    if (!this.oListObserver) {
      this.createDocListOberserver();
      return;
    }
    this.oListObserver.disconnect();
    this.oListObserver.observe(this.oDocListObsRef);
  }

  handleLoadMoreDocs = ([elDocList]) => {
    const { oConfig } = this.state;

    if (!elDocList.isIntersecting) return;

    if (oConfig.bShowObsolete) {
      this.fetchArchivedFolders();
    } else {
      this.fetchFolders();
    }
  }

  fetchFiledDocuments = () => {
    const { setAlert } = this.props;

    Axios.get('/filed')
      .then(oRes => {
        this.setState({
          aFiledDocuments: oRes.data
        });
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
      });
  };

  fetchArchivedFolders = async (bResetCount) => {
    const { setAlert } = this.props;
    const { nFolderId, nSize, aArchivedFolders, aFiledDocuments, bInternalLoad } = this.state;
    const cModule = 'documents';

    if (!can('admin', cModule)) return;

    if (bInternalLoad) {
      return;
    }

    this.setState({
      bInternalLoad: true
    });

    try {
      const oResponse = await Axios.get('/folder-archived', {
        params: {
          nId: nFolderId,
          nSize,
          nQtyFolders: bResetCount ? 0 : aArchivedFolders.length,
          nQtyDocuments: bResetCount ? 0 : aFiledDocuments.length
        }
      });
      const { data: oData } = oResponse;

      const aNewFolders =
        aArchivedFolders.length > 0 && !bResetCount
          ? aArchivedFolders.concat(oData.aFolders)
          : oData.aFolders;

      const aNewDocuments =
        aFiledDocuments.length > 0 && !bResetCount
          ? aFiledDocuments.concat(oData.aDocuments)
          : oData.aDocuments;

      if (oData.aFolders.length === 0 && oData.aDocuments.length === 0) {
        this.oListObserver.disconnect();
      }

      this.setState({
        aFiledDocuments: aNewDocuments,
        aArchivedFolders: aNewFolders,
        bLoading: false,
        bInternalLoad: false,
        nQtylist: oData.nTotal,
        ...(oData.oFolder && { oFolder: oData.oFolder }),
      });
    } catch (oError) {
      setAlert('error', oError.aMsgErrors);
      this.setState({ bInternalLoad: false, bLoading: false });
    }
  };

  fetchFolders = async (changeOrder = false) => {
    const { setAlert } = this.props;
    const { nFolderId, oConfig, nSize, aFolders, aDocuments, bInternalLoad, cSearch } = this.state;

    if (bInternalLoad) return;

    this.setState({ bInternalLoad: true });

    try {
      const oData = await getFolders(
        nFolderId,
        nSize,
        !changeOrder ? aFolders.length : 0,
        !changeOrder ? aDocuments.length : 0,
        oConfig.cOrderBy,
        oConfig.cType,
        cSearch !== '' ? cSearch : undefined
      );

      const aNewFolders = !changeOrder && aFolders.length > 0
        ? aFolders.concat(oData.aFolders)
        : oData.aFolders;
      const aNewDocuments = !changeOrder && aDocuments.length > 0
        ? aDocuments.concat(oData.aDocuments)
        : oData.aDocuments;

      if (oData.aFolders.length === 0 && oData.aDocuments.length === 0) {
        this.oListObserver.disconnect();
      }

      this.setState({
        aFolders: aNewFolders,
        aDocuments: aNewDocuments,
        bLoading: false,
        bInternalLoad: false,
        nQtylist: oData.nTotal,
        ...(oData.oFolder && { oFolder: oData.oFolder }),
      });
    } catch (oError) {
      setAlert('error', oError.aMsgErrors);
      this.setState({ bInternalLoad: false, bLoading: false });
    }
  };

  fetchDocumentsAccessed = () => {
    const { nFolderId } = this.state;

    if (!nFolderId || Number(nFolderId) === 1) {
      Axios.get('/document/latest-accesses/4').then(oResponse => {
        if (typeof oResponse === 'undefined') {
          return;
        }
        if (this.bIsMounted) {
          const aRecentDocuments = [];

          Object.keys(oResponse.data).map(i => {
            if (oResponse.data[i].published_version !== undefined) {
              aRecentDocuments.push(oResponse.data[i]);
            }
          });

          this.setState({
            aRecentDocuments
          });
        }
      });
    }
  };

  handleJoyrideCallback = oData => {
    const { cStatus } = oData;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(cStatus)) {
      this.setState({ bRun: false });
    }
  };

  changeView = evt => {
    const { oConfig } = this.state;

    evt.preventDefault();
    const elTarget = evt.target.parentNode.nodeName === 'DIV' ? evt.target : evt.target.parentNode;
    oConfig.cViewType = elTarget.getAttribute('view');
    this.setState({
      oConfig
    });

    localStorage.setItem('cConfig', JSON.stringify(oConfig));
  };

  showDeleteDocument = (evt, oDocument) => {
    if (evt) {
      evt.preventDefault();
    }
    this.setState({
      rcmpAlert: (
        <SweetAlert
          warning
          showCancel
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancelar')}
          cancelBtnBsStyle="danger"
          title={intl.get('DocumentEditor.descartar_documento')}
          onConfirm={() => this.deleteDocument(oDocument)}
          onCancel={this.hideAlert}
        >
          {intl.get('FolderIndex.mensagem_excluir_documento', { cName: oDocument.doc_ctitle })}
        </SweetAlert>
      )
    });
  }

  showDeleteFolder = (evtHandler, oFolder) => {
    evtHandler.preventDefault();
    this.setState({
      rcmpAlert: (
        <SweetAlert
          warning
          showCancel
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancelar')}
          cancelBtnBsStyle="danger"
          title={`${intl.get('remover')} ${oFolder.fldr_cname}`}
          onConfirm={() => this.deleteFolder(oFolder)}
          onCancel={this.hideAlert}
        >
          {intl.get('FolderIndex.remove_folder', { cFolderName: oFolder.fldr_cname })}
        </SweetAlert>
      )
    });
  };

  showFolderFile = (evtHandler, oFolder) => {
    evtHandler.preventDefault();
    this.setState({
      rcmpAlert: (
        <SweetAlert
          warning
          showCancel
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancelar')}
          cancelBtnBsStyle="danger"
          title={`${intl.get('file_folder_title')} ${oFolder.fldr_cname}`}
          onConfirm={() => this.folderFile(oFolder)}
          onCancel={this.hideAlert}
        >
          {intl.get('FolderIndex.file_folder', { cFolderName: oFolder.fldr_cname })}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
  };

  handleNewFilter = (evt, oFilter) => {
    this.setState(
      {
        cSearch: oFilter.cText,
        oConfig: {
          ...this.state.oConfig,
          cOrderBy: oFilter.cOrderBy,
          cType: oFilter.cOrderType
        }
      },
      () => {
        this.handleFilter()
      }
    );
  };

  handleFilter = async () => {
    const { oConfig } = this.state;
    if (!oConfig.bShowObsolete) {
      await this.fetchFolders(true);
    } else {
      await this.fetchArchivedFolders(true);
    }
    this.refreshDocListObserver();
  };

  deleteFolder = oFolder => {
    Axios.put(`trashbin/send-folder/${oFolder.fldr_nid}`)
      .then(() => {
        this.fetchFolders(true);
        this.fetchArchivedFolders(true);
        this.fetchDocumentsAccessed();
        this.setState({
          rcmpAlert: (
            <SweetAlert
              success
              title={intl.get('removido')}
              onConfirm={() => {
                this.hideAlert();
              }}
            >
              {oFolder.fldr_cname} {intl.get('FolderIndex.excluir_sucesso')}
            </SweetAlert>
          )
        });
      })
      .catch(oError => {
        this.setState({
          rcmpAlert: (
            <SweetAlert error title={intl.get('opa')} onConfirm={this.hideAlert}>
              {oError.aMsgErrors}
            </SweetAlert>
          )
        });
      });
  };

  folderFile = oFolder => {
    Axios.put(`folder-file/${oFolder.fldr_nid}`)
      .then(() => {
        this.setState({
          rcmpAlert: (
            <SweetAlert
              success
              title={intl.get('FolderIndex.filed_folder_title')}
              onConfirm={() => {
                this.fetchFolders();
                this.fetchArchivedFolders(true);
                this.fetchDocumentsAccessed();
                this.hideAlert();
              }}
            >
              {intl.get('FolderIndex.file_folder_success')}
            </SweetAlert>
          )
        });
      })
      .catch(oError => {
        this.setState({
          rcmpAlert: (
            <SweetAlert error title={intl.get('opa')} onConfirm={this.hideAlert}>
              {oError.aMsgErrors}
            </SweetAlert>
          )
        });
      });
  };

  showRestoreModal = async (evtTriggerd, oDocument) => {
    if (evtTriggerd != null) {
      evtTriggerd.stopPropagation();
    }

    this.setState({
      rcmpAlert: (
        <SweetAlert
          warning
          showCancel
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancelar')}
          confirmBtnBsStyle="danger"
          title={intl.get('Backup.arquivo')}
          onConfirm={() => this.validateDocumentRestore(null, oDocument)}
          onCancel={this.hideAlert}
        >
          {intl.get('FolderIndex.mensagem_restaurar_documento', { cName: oDocument.doc_ctitle })}
        </SweetAlert>
      )
    });
  };

  showSendToFile = (evtTriggered, oDocument) => {
    if (evtTriggered != null) {
      evtTriggered.stopPropagation();
    }

    this.setState({
      rcmpAlert: (
        <SweetAlert
          warning
          showCancel
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancelar')}
          cancelBtnBsStyle="danger"
          title=""
          onConfirm={() => this.sendToFile(oDocument)}
          onCancel={this.hideAlert}
        >
          {intl.get('FolderIndex.mensagem_arquivar_documento', { cName: oDocument.doc_ctitle })}
        </SweetAlert>
      )
    });
  };

  handleOpenDocumentEditModal = (evt, oDocument) => {
    if (evt) {
      evt.stopPropagation();
    }
    const { history, setAlert } = this.props;
    this.setState({
      rcmpAlert: (
        <SweetAlert
          customClass="modal-edit modal-edit-v2 lg scrollBar"
          showConfirm={false}
          onConfirm={this.hideAlert}
          onCancel={this.hideAlert}
          title={null}
        >
          <div className="head-modal">
            {intl.get('DocumentDetails.editar_documento')}
            <span
              role="button"
              tabIndex="0"
              onClick={this.hideAlert}
              className="close"
            />
          </div>
          <ScrollBar>
            <Document
              bNewDocument={false}
              nVersionId={oDocument.oVersion.ver_nid}
              onSuccess={async (cMsg) => {
                // atualiza a página quando altera um documento, mudar no futuro para atualizar a lista dinamicamente
                history.push({
                  state: { aAlertMessages: { cType: 'success', cMsg } }
                });
              }}
              onError={(cMsg) => setAlert('error', cMsg)}
              closeModal={this.hideAlert}
            />
          </ScrollBar>
        </SweetAlert>
      )
    });
  }

  sendToDuplicate = (evtTriggered, nVersionId) => {
    const { history } = this.props;
    evtTriggered.stopPropagation();
    history.push(`/document/copy/${nVersionId}`);
  };

  sendToFile = oDocument => {
    const { bIsSubmitting } = this.state;
    if (bIsSubmitting) return;

    this.setState({ bIsSubmitting: true });

    Axios.put(`/file/${oDocument.doc_nid}`)
      .then(() => {
        this.setState({
          rcmpAlert: (
            <SweetAlert
              success
              title=""
              onConfirm={() => {
                this.fetchFolders(true);
                this.fetchArchivedFolders(true);
                this.fetchDocumentsAccessed();
                this.hideAlert();
              }}
            >
              {intl.get('FolderIndex.arquivado_com_sucesso')}
            </SweetAlert>
          ),
          bIsSubmitting: false,
        });
      })
      .catch(() => {
        this.setState({
          rcmpAlert: (
            <SweetAlert error title="Opa!" onConfirm={this.hideAlert}>
              {intl.get('falhou')}
            </SweetAlert>
          ),
          bIsSubmitting: false,
        });
      });
  };

  deleteDocument = async (oDocument) => {
    const { bIsSubmitting } = this.state;
    if (bIsSubmitting) return;

    this.setState({ bIsSubmitting: true });
    try {
      await sendToTrashBin(oDocument.oVersion.ver_nid);
      this.setState({
        rcmpAlert: (
          <SweetAlert
            success
            title=""
            onConfirm={() => {
              this.fetchFolders(true);
              this.fetchArchivedFolders(true);
              this.fetchDocumentsAccessed();
              this.hideAlert();
            }}
          >
            {intl.get('DocumentEditor.mensagem_removido')}
          </SweetAlert>
        ),
        bIsSubmitting: false,
      });
    } catch (oError) {
      this.setState({
        rcmpAlert: (
          <SweetAlert error title={intl.get('opa')} onConfirm={this.hideAlert}>
            {intl.get('falhou')}
          </SweetAlert>
        ),
        bIsSubmitting: false,
      });
    }
  }

  unfileDocument = oDocument => {
    const { nRestoringFolderId, bIsSubmitting } = this.state;

    if (bIsSubmitting) return;
    this.setState({ bIsSubmitting: true });

    unfile(oDocument.doc_nid, nRestoringFolderId)
      .then(oResponse => {
        this.setState({
          rcmpAlert: (
            <SweetAlert
              success
              title={intl.get('FolderIndex.restore_title')}
              onConfirm={() => {
                this.fetchFolders(true);
                this.fetchArchivedFolders(true);
                this.fetchDocumentsAccessed();
                this.hideAlert();
              }}
            >
              {intl.get('FolderIndex.restore_success')}
            </SweetAlert>
          ),
          bIsSubmitting: false,
        });
      })
      .catch(oError => {
        this.setState({
          rcmpAlert: (
            <SweetAlert error title={intl.get('FolderIndex.restore_title')} onConfirm={this.hideAlert}>
              {intl.get('FolderIndex.restore_error')}
            </SweetAlert>
          ),
          bIsSubmitting: false,
        });
      });
  };

  handleChangeRestoreFolder = evtChangeFolder => {
    this.setState({
      nRestoringFolderId: evtChangeFolder.target.value
    });
  };

  moveFiledDocumentAlert = (oDocument) => {
    return (
      <SweetAlert
        showCancel
        confirmBtnText={intl.get('confirmar')}
        cancelBtnText={intl.get('cancelar')}
        cancelBtnBsStyle="danger"
        title={intl.get('FolderIndex.restore_title')}
        onConfirm={() => this.unfileDocument(oDocument)}
        onCancel={this.hideAlert}
      >
        <label className="split2" htmlFor="tree-view">
          {intl.get('FolderIndex.choose_your_folder')}
          <TreeViewLayer
            nFolderId={oDocument.fldr_nid}
            oFolderTree={[]}
            bRequest={null}
            fnSelectFolder={evtSelectFolder => this.handleChangeRestoreFolder(evtSelectFolder)}
            className="modal"
            cHeight="180px"
            bOutsideRender
          />
        </label>
      </SweetAlert>
    );
  };

  validateDocumentRestore = async (evtTriggerd, oDocument) => {
    if (evtTriggerd != null) {
      evtTriggerd.stopPropagation();
    }

    const oData = await getDocumentDetails(oDocument.oVersion.ver_nid);
    const oDocumentUpdated = oData.oDocument;
    if (oDocumentUpdated.folder.fldr_bisactive === 0 || oDocumentUpdated.folder.fldr_ddeleted != null) {
      this.setState({
        rcmpAlert: this.moveFiledDocumentAlert(oDocumentUpdated),
      });
    } else {
      this.unfileDocument(oDocumentUpdated);
    }
  };

  closeSubmenu = (evt, elWrapperRef) => {
    const elSubmenu = elWrapperRef;
    if (elSubmenu.getElementsByClassName('submenu')[0]) {
      elSubmenu.getElementsByClassName('submenu')[0].style = 'display: none';
    }
  };

  restoreFolder = nFolderId => {
    Axios.put(`folder-unarchive/${nFolderId}`)
      .then(() => {
        this.setState({
          rcmpAlert: (
            <SweetAlert
              success
              title={intl.get('FolderIndex.restore_title')}
              onConfirm={() => {
                this.fetchFolders(true);
                this.fetchArchivedFolders(true);
                this.fetchDocumentsAccessed();
                this.hideAlert();
              }}
            >
              {intl.get('FolderIndex.restore_folder_success')}
            </SweetAlert>
          )
        });
      })
      .catch(oError => {
        this.setState({
          rcmpAlert: (
            <SweetAlert error title={intl.get('opa')} onConfirm={this.hideAlert}>
              {oError.aMsgErrors}
            </SweetAlert>
          )
        });
      });
  };

  validateFolderRestore = oFolder => {
    if (oFolder.parent_folder.fldr_bisactive === 0) {
      this.setState({
        rcmpAlert: (
          <SweetAlert
            showCancel
            confirmBtnText={intl.get('confirmar')}
            cancelBtnText={intl.get('cancelar')}
            cancelBtnBsStyle="danger"
            title={intl.get('FolderIndex.restore_title')}
            onConfirm={() => this.restoreFolder(oFolder.fldr_nid)}
            onCancel={this.hideAlert}
          >
            {intl.get('FolderIndex.restore_folder_warning')}
          </SweetAlert>
        )
      });
    } else {
      this.restoreFolder(oFolder.fldr_nid);
    }
  };

  renderFolderSubmenu = (oFolder, bMenuFolder = false) => {
    const { oConfig } = this.state;
    let rcmpList = null;

    if (oConfig.bShowObsolete) {
      rcmpList = (
        <li>
          <span
            role="button"
            tabIndex="0"
            onClick={evtClick => {
              evtClick.stopPropagation();
              this.validateFolderRestore(oFolder);
            }}
            onKeyPress={evtClick => {
              evtClick.stopPropagation();
              this.validateFolderRestore(oFolder);
            }}
            title={intl.get('FolderIndex.restore')}
          >
            {intl.get('FolderIndex.restore')}
          </span>
        </li>
      );
    } else if (oFolder.fldr_nid_parent !== null) {
      rcmpList = (
        <>
          <li>
            <NavLink
              onClick={evtClick => {
                evtClick.stopPropagation();
                this.openModalFolderMove(evtClick, oFolder);
              }}
              to="/#openEditFolder"
              title={intl.get('mover')}
            >
              <i className="icon-move" />
              {intl.get('mover')}
            </NavLink>
          </li>

          <li>
            <NavLink
              onClick={evtClick => {
                evtClick.stopPropagation();
                this.openModalFolderInfo(evtClick, oFolder);
              }}
              to="/#openFolderInfo"
              title={intl.get('visualizar')}
            >
              <i className="icon-eye" />
              {intl.get('visualizar')}
            </NavLink>
          </li>

          <li>
            <NavLink
              onClick={evtClick => {
                evtClick.stopPropagation();
                this.openModalFolderEdit(evtClick, oFolder);
              }}
              to="/#openEditFolder"
              title={intl.get('edit', { cType: '' })}
            >
              <i className="icon-edit" />
              {intl.get('edit', { cType: '' })}
            </NavLink>
          </li>

          <li>
            <NavLink
              onClick={evtClick => {
                evtClick.stopPropagation();
                this.showDeleteFolder(evtClick, oFolder);
              }}
              to="/#openDeleteFolder"
              title={intl.get('FolderIndex.delete')}
            >
              <i className="icon-bin" />
              {intl.get('FolderIndex.delete')}
            </NavLink>
          </li>

          <li>
            <NavLink
              onClick={evtClick => {
                evtClick.stopPropagation();
                this.showFolderFile(evtClick, oFolder);
              }}
              to="/#openFileFolder"
              title={intl.get('FolderIndex.file')}
            >
              <i className="icon-drawer" />
              {intl.get('FolderIndex.file')}
            </NavLink>
          </li>
        </>
      );
    } else {
      rcmpList = (
        <>
          <li>
            <NavLink
              onClick={evt => {
                evt.stopPropagation();
                this.openModalFolderInfo(evt, oFolder);
              }}
              to="/#openFolderInfo"
              title={intl.get('visualizar')}
            >
              <i className="icon-eye" />
              {intl.get('visualizar')}
            </NavLink>
          </li>

          <li>
            <NavLink
              onClick={evt => {
                evt.stopPropagation();
                this.openModalFolderEdit(evt, oFolder);
              }}
              to="/#openEditFolder"
              title={intl.get('edit', { cType: '' })}
            >
              <i className="icon-edit" />
              {intl.get('edit', { cType: '' })}
            </NavLink>
          </li>
        </>
      );
    }
    return (
      <OutsideHandlerClick
        handleClickOutside={(evtClick, elWrapperRef) => {
          this.closeSubmenu(evtClick, elWrapperRef);
          if (bMenuFolder) this.setState({ bActualMenuFolder: false });
        }}
      >
        <ul className="submenu">{rcmpList}</ul>
      </OutsideHandlerClick>
    );
  };

  renderDocumentSubmenu = oDocument => {
    const { oConfig, oFolder } = this.state;
    const cModule = 'documents';

    const bIsPublishedVersion = oDocument.published_version !== undefined || oDocument.oVersion.stat_nid === 4;

    const aMenuItems = [];
    if (oConfig.bShowObsolete && can('admin', cModule)) {
      aMenuItems.push(
        <li>
          <span
            role="button"
            tabIndex="0"
            onClick={evtClick => this.validateDocumentRestore(evtClick, oDocument)}
            onKeyPress={evtKeyPress => this.validateDocumentRestore(evtKeyPress, oDocument)}
            title={intl.get('FolderIndex.restore')}
          >
            {intl.get('FolderIndex.restore')}
          </span>
        </li>
      );
    } else if (oFolder.bCanCreate || can('admin')) {
      aMenuItems.push(
        <li key="edit-document">
          <span
            role="button"
            tabIndex="0"
            onClick={evtClick => this.handleOpenDocumentEditModal(evtClick, oDocument)}
            onKeyPress={evtKeyPress => this.handleOpenDocumentEditModal(evtKeyPress, oDocument)}
            title={intl.get('QualityPanelIndex.editar')}
          >
            <i className="icon-edit" />
            {intl.get('QualityPanelIndex.editar')}
          </span>
        </li>
      );
      aMenuItems.push(
        <li key="move-document">
          <span
            role="button"
            tabIndex="0"
            onClick={evtClick => this.openModalMoveDocument(evtClick, oDocument)}
            onKeyPress={evtKeyPress => this.openModalMoveDocument(evtKeyPress, oDocument)}
            title={intl.get('FolderIndex.title_mover')}
          >
            <i className="icon-move" />
            {intl.get('mover')}
          </span>
        </li>
      );
      if (bIsPublishedVersion) {
        aMenuItems.push(
          <li key="duplicate-document">
            <span
              role="button"
              tabIndex="0"
              onClick={evtClick => this.sendToDuplicate(evtClick, oDocument.oVersion.ver_nid)}
              onKeyPress={evtKeyPress => this.sendToDuplicate(evtKeyPress, oDocument.oVersion.ver_nid)}
              title={intl.get('FolderIndex.title_copia')}
            >
              <i className="icon-duplicate" />
              {intl.get('duplicar')}
            </span>
          </li>
        );
      }
    } else if (bIsPublishedVersion) {
      aMenuItems.push(
        <li key="request-document">
          <span
            role="button"
            tabIndex="0"
            onClick={evtClick => this.openModalRequestFile(evtClick, oDocument.oVersion.ver_nid)}
            onKeyPress={evtKeyPress => this.openModalRequestFile(evtKeyPress, oDocument.oVersion.ver_nid)}
            title={intl.get('FolderIndex.title_solicitar_envio_arquivo')}
          >
            <i className="icon-document-remove" />
            {intl.get('FolderIndex.label_solicitar_envio_arquivo')}
          </span>
        </li>
      );
    }

    if (can('admin') || oDocument.oResponsible?.user_nid === +oLocalStorage.get('nUserId')) {
      if (bIsPublishedVersion) {
        aMenuItems.push(
          <li key="archive-document">
            <span
              role="button"
              tabIndex="0"
              onClick={evtClick => this.showSendToFile(evtClick, oDocument)}
              onKeyPress={evtKeyPress => this.showSendToFile(evtKeyPress, oDocument)}
              title={intl.get('FolderIndex.title_arquivo')}
            >
              <i className="icon-document-remove" />
              {intl.get('FolderIndex.label_enviar_arquivo')}
            </span>
          </li>
        );
      } else {
        aMenuItems.push(
          <li key="delete-document">
            <span
              role="button"
              tabIndex="0"
              onClick={evtClick => this.showDeleteDocument(evtClick, oDocument)}
              onKeyPress={evtKeyPress => this.showDeleteDocument(evtKeyPress, oDocument)}
              title={intl.get('DocumentEditor.descartar_documento')}
            >
              <i className="icon-bin" />
              {intl.get('DocumentEditor.descartar_documento')}
            </span>
          </li>
        );
      }
    }

    return (
      <OutsideHandlerClick handleClickOutside={this.closeSubmenu}>
        <ul className="submenu">{aMenuItems}</ul>
      </OutsideHandlerClick>
    );
  };

  openDocumentSubmenu = evtTriggerd => {
    evtTriggerd.stopPropagation();
    if (evtTriggerd.target.closest('div').getElementsByClassName('submenu')) {
      evtTriggerd.target.closest('div').getElementsByClassName('submenu')[0].style = 'display: block';
    }
  };

  openFolderSubmenu = evt => {
    if (evt.target.closest('div').getElementsByClassName('submenu')) {
      evt.target.closest('div').getElementsByClassName('submenu')[0].style = 'display: inline-block';
    }
  };

  errorRequest = cMsg => {
    const { setAlert } = this.props;
    this.hideAlert();
    setAlert('error', cMsg);
  };

  openRequestModal = evt => {
    evt.preventDefault();
    const { setAlert } = this.props;
    this.setState({
      rcmpAlert: (
        <RequestModal
          cTitle={intl.get('SmartButton.request_document')}
          cType="new"
          onSuccess={this.successMessage}
          hideAlert={this.hideAlert}
          onError={this.errorRequest}
          setAlert={setAlert}
          closeModal={this.hideAlert}
        />
      )
    });
  };

  openModalRequestFile = (evtTriggered, nVersionId) => {
    const { setAlert } = this.props;
    evtTriggered.preventDefault();
    evtTriggered.stopPropagation();

    this.setState({
      rcmpAlert: (
        <RequestModal
          cTitle={intl.get('FolderIndex.label_solicitar_envio_arquivo')}
          cType="delete"
          nVersionId={nVersionId}
          onSuccess={this.successMessage}
          hideAlert={this.hideAlert}
          setAlert={setAlert}
          closeModal={this.hideAlert}
        />
      )
    });
  };

  showPreviewDocumentModal = (oDocument) => {
    this.setState({
      rcmpAlert: (
        <SweetAlert
          showCancel
          showConfirm={false}
          onConfirm={this.hideAlert}
          onCancel={this.hideAlert}
          cancelBtnText={intl.get('close')}
          closeOnClickOutside
          title=""
          customClass="modal-edit modal-document-preview scrollBar"
        >
          <div className="head-modal">
            {`${intl.get("visualizar")}: ${oDocument.doc_ctitle}`}
            <span
              aria-labelledby="head-modal"
              tabIndex={0}
              onClick={this.hideAlert}
              className="close"
              role="button"
              onKeyPress={this.hideAlert}
            />
          </div>
          <ScrollBar>
            <DocumentPreview oDocument={oDocument} />
          </ScrollBar>
        </SweetAlert>
      )
    });
  }

  showDocumentStageModal = (oDocument, cStatus, cName) => {
    this.setState({
      rcmpAlert: (
        <SweetAlert
          showCancel
          closeOnClickOutside
          cancelBtnText={intl.get('cancelar')}
          confirmBtnText={intl.get('visualizar')}
          cancelBtnBsStyle="primary"
          onCancel={this.hideAlert}
          onConfirm={() => this.showPreviewDocumentModal(oDocument)}
          title=""
          customClass="modal-edit disabled-overflow"
        >
          <div className="head-modal">
            {intl.get('Document.document_information')}
            <span
              aria-labelledby="head-modal"
              tabIndex={0}
              onClick={this.hideAlert}
              className="close"
              role="button"
              onKeyPress={this.hideAlert}
            />
          </div>
          <div>
            <p>{[intl.get('Document.awaiting'), cStatus, intl.get('Document.by'), cName].join(' ')}</p>
          </div>
        </SweetAlert>
      )
    });
  }

  setFilter = (cOrder, cType) => {
    const { aFolders, aDocuments, nQtylist, nSize, oConfig } = this.state;

    oConfig.cOrderBy = cOrder ?? 'cName';
    oConfig.cType = cType;
    localStorage.setItem('cConfig', JSON.stringify(oConfig));

    const bOrderByFront = nQtylist < nSize;
    if (!bOrderByFront) {
      return this.setState({ oConfig }, () => this.fetchFolders(true));
    }

    let aNewOrderFolders;
    let aNewOrderDocuments;
    switch (cOrder) {
      case 'cName':
        // ignora acentos e caracteres especiais ao ordenar
        aNewOrderFolders = aFolders.sort((oFolder1, oFolder2) => {
          return (cType === 'asc' ? 1 : -1) * oFolder1.fldr_cname.localeCompare(oFolder2.fldr_cname);
        });
        aNewOrderDocuments = aDocuments.sort((oDoc1, oDoc2) => {
          return (cType === 'asc' ? 1 : -1) * oDoc1.doc_ctitle.localeCompare(oDoc2.doc_ctitle);
        });
        break;
      case 'cVersion':
        aNewOrderDocuments = _.orderBy(aDocuments, [oDocument => oDocument.oVersion.ver_cnumber], [cType]);
        break;
      case 'cStatus':
        aNewOrderDocuments = _.orderBy(aDocuments, [oDocument => oDocument.oVersion.stat_nid], [cType]);
        break;
      case 'cResponsibleName':
        aNewOrderDocuments = _.orderBy(
          aDocuments,
          [oDocument => oDocument.oResponsible.user_cname.toLowerCase()],
          [cType]
        );
        break;
      case 'cPublishedDate':
        aNewOrderDocuments = _.orderBy(aDocuments, [oDocument => oDocument.oVersion.ver_dpublished], [cType]);
        break;
      case 'cDueDate':
        aNewOrderDocuments = _.orderBy(aDocuments, [oDocument => oDocument.oVersion.dtReviewDate], [cType]);
        break;
      case 'nReadings':
        aNewOrderDocuments = _.orderBy(aDocuments, [oDocument => oDocument.nReadings], [cType]);
        break;
    }

    this.setState({
      aFolders: aNewOrderFolders ?? aFolders,
      aDocuments: aNewOrderDocuments ?? aDocuments,
      oConfig,
    });
  };

  successMessage = (cMsg, bRefresh = false) => {
    const { setAlert } = this.props;
    const { nFolderId } = this.state;
    this.hideAlert();
    setAlert('success', cMsg);
    this.fetchFolders(bRefresh);
  };

  openModalMoveDocument = (evtTriggered, oDocument) => {
    evtTriggered.preventDefault();
    evtTriggered.stopPropagation();
    this.setState({
      rcmpAlert: (
        <SweetAlert
          customClass="modal-edit modalMedium scrollBar"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            {intl.get('DocumentMove.mover_documento')}
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyPress={this.hideAlert}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>
          <ScrollBar>
            <DocumentMove
              oDocument={oDocument}
              onSuccess={cMsg => {
                this.successMessage(cMsg);
              }}
              closeModal={this.hideAlert}
            />
          </ScrollBar>
        </SweetAlert>
      )
    });
  };

  openFolderModal = (evt, oFolder) => {
    evt.preventDefault();

    this.setState({
      rcmpAlert: (
        <SweetAlert
          customClass="modal-edit modalMedium scrollBar"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            {intl.get('FolderIndex.editar_pasta')}
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyPress={this.hideAlert}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>
          <ScrollBar>
            <Folder
              nId={oFolder.fldr_nid}
              bNewFolder
              onSuccess={cMsg => {
                this.successMessage(cMsg, true);
                this.setState({
                  bIsProcessing: false
                });
              }}
              closeModal={() => {
                this.hideAlert();
                this.setState({
                  bIsProcessing: false
                });
              }}
            />
          </ScrollBar>
        </SweetAlert>
      ),
      bIsProcessing: true
    });
  };

  redirectNewFront = (evtTriggered, oFolder) => {
    evtTriggered.preventDefault();
    const { history } = this.props;
    history.push(`/dashboard/folder/${oFolder.fldr_nid}/files`);
  };

  docLink = (evtTriggered, cPublishedVersion) => {
    const { history } = this.props;

    evtTriggered.stopPropagation();

    history.push(`/document/detail/${cPublishedVersion}`);
  };

  handleClickOutside(evt) {
    if (this.wrapperRef && !this.wrapperRef.contains(evt.target)) {
      this.setState({
        bActualMenuFolder: false
      });
    }
  }

  openModalFolderInfo = (evt, oFolderItem) => {
    evt.preventDefault();
    this.setState({
      rcmpAlert: (
        <SweetAlert
          customClass="modal-edit modalMedium scrollBar"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            <span className="title">{oFolderItem.fldr_cname}</span>
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyPress={this.hideAlert}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>
          <ScrollBar>
            <FolderShow nFolderId={oFolderItem.fldr_nid} />
          </ScrollBar>
        </SweetAlert>
      )
    });
  };

  openModalFolderEdit = (evt, oFolderItem) => {
    evt.preventDefault();
    this.setState({
      rcmpAlert: (
        <SweetAlert
          customClass="modal-edit modalMedium scrollBar"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            {intl.get('FolderIndex.editar_pasta')}
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyPress={this.hideAlert}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>
          <ScrollBar>
            <Folder
              nId={oFolderItem.fldr_nid}
              bNewFolder={false}
              onSuccess={cMsg => {
                this.successMessage(cMsg);
              }}
              closeModal={this.hideAlert}
            />
          </ScrollBar>
        </SweetAlert>
      )
    });
  };

  openModalFolderMove = (evt, oFolder) => {
    evt.preventDefault();
    this.setState({
      rcmpAlert: (
        <SweetAlert
          customClass="modal-edit modalMedium scrollBar"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            {intl.get('FolderIndex.mover_pasta')}: {oFolder.fldr_cname}
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyPress={this.hideAlert}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>
          <ScrollBar>
            <FolderMove
              nFolderId={oFolder.fldr_nid}
              fnCloseModal={this.hideAlert}
              fnErrorRequest={this.errorRequest}
              history={this.props.history}
            />
          </ScrollBar>
        </SweetAlert>
      )
    });
  };

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value });
  };

  menuOpen = () => {
    this.setState(oCurrentState => ({
      bActualMenuFolder: !oCurrentState.bActualMenuFolder
    }));
  };

  // eslint-disable-next-line class-methods-use-this
  generateCardHeader = () => {
    const { oConfig, oFolder, nFolderId, bActualMenuFolder, bIsProcessing } = this.state;
    const { history } = this.props;
    const bIsItToInsertGoBackBtn = nFolderId !== undefined && nFolderId !== 1;
    const aCheckboxes = [];
    const cModule = 'documents';

    let rcmpFolderMenu = null;
    if (hasModule(cModule) && (can('admin') || oFolder.bCanCreate) && !oConfig.bShowObsolete) {
      rcmpFolderMenu = (
        <div className="right-menu">
          <span
            role="button"
            tabIndex="0"
            onKeyPress={() => this.menuOpen()}
            onClick={() => this.menuOpen()}
            title=""
            className="btn-menu"
          >
            <span className="insideP" />
          </span>
          {bActualMenuFolder && this.renderFolderSubmenu(oFolder, true)}
        </div>
      );
    }

    if (can('admin', cModule)) {
      aCheckboxes.push(
        <AltCheckbox
          key="checkbox-dead-file"
          onChange={evt => {
            this.handleChange(evt);
            oConfig.bShowObsolete = evt.target.checked;
            this.setState({ oConfig });
            this.refreshDocListObserver();
            localStorage.setItem('cConfig', JSON.stringify(oConfig));
          }}
          title={intl.get('Nav.filed_documents')}
          label={intl.get('Nav.filed_documents')}
          name="bShowObsolete"
          localeLabel="end"
          checked={oConfig.bShowObsolete}
        />
      );
    }

    const aOrderItems = [
      { value: 'cName', label: intl.get('name') },
      { value: 'cVersion', label: intl.get('MasterList.version') },
      { value: 'cStatus', label: intl.get('status') },
      { value: 'cResponsibleName', label: intl.get('responsible') },
      { value: 'cPublishedDate', label: intl.get('FolderIndex.published_at') },
      { value: 'cDueDate', label: intl.get('FolderIndex.finish_at') },
      { value: 'nReadings', label: intl.get('Dashboard.readings') }
    ];
    const cDefaultOrder = aOrderItems.find((oItem) => oItem.value === oConfig.cOrderBy)?.value ?? '';

    const bIsOrderActive = oConfig.cViewType === 'grid' && window.innerWidth > 600;

    const aItems = [];
    aItems.push(
      <NavItem
        key="navitem-cards"
        cIcon={oConfig.cViewType === 'list' ? imgfCards : imgfGrid}
        cText="Cards"
        className="navitem-viewtype"
        onClick={() => {
          if (oConfig.cViewType === 'list') {
            oConfig.cViewType = 'grid';
          } else {
            oConfig.cViewType = 'list';
          }
          this.setState({ oConfig });
          localStorage.setItem('cConfig', JSON.stringify(oConfig));
        }}
      />
    );

    const aButtons = [];
    if (hasModule(cModule) && (can('admin') || oFolder.bCanCreate)) {
      aButtons.push([
        <Button key="send-file" onClick={evt => this.redirectNewFront(evt, oFolder)}>
          <span className="btn-content-desktop">{intl.get('send_file')}</span>
        </Button>,
        <Button
          key="button-new-document"
          className="btn-document-new"
          color="primary"
          onClick={evt => this.openCreateDocumentModal(evt, oFolder)}
          disabled={bIsProcessing}
          style={{ pointerEvents: this.bIsProcessing ? 'none' : 'auto' }}
        >
          <span className="btn-content-desktop">{intl.get('new_document')}</span>
        </Button>
        ,
        <Button key="new-folder" onClick={evt => this.openFolderModal(evt, oFolder)}>
          <span className="btn-content-desktop">{intl.get('new_folder')}</span>
        </Button>
      ]);
    }
    aButtons.push(
      <Button key="new-request" onClick={evt => this.openRequestModal(evt)}>
        <span className="btn-content-desktop">{intl.get('new_request')}</span>
      </Button>
    );

    const rcmpFilter = (
      <Filter
        aButtons={aButtons}
        aCheckboxes={aCheckboxes}
        aOrderItems={aOrderItems}
        cDefaultOrder={cDefaultOrder}
        bIsOrderActive={bIsOrderActive}
        aItems={aItems}
        fnOnType={this.handleNewFilter}
        rcmpRightMenu={rcmpFolderMenu}
        className={bIsOrderActive ? 'folderindex-filter-withOrder' : 'folderindex-filter-withoutOrder'}
      />
    );
    const rcmpCardHeader = (
      <CardHeader rcmpFilter={rcmpFilter} bIsItToInsertGoBackBtn={bIsItToInsertGoBackBtn} history={history} />
    );
    return rcmpCardHeader;
  };

  openCreateDocumentModal = (evt, oFolder) => {
    evt.preventDefault();

    // Se o estado bIsProcessing ou bDebounceLock estiverem ativados, retorna e não faz nada
    const { bIsProcessing } = this.state;
    if (bIsProcessing || this.bDebounceLock) return;

    // Ativar o bIsProcessing e o bDebounceLock
    this.setState({ bIsProcessing: true });
    this.bDebounceLock = true;

    // Desativar o bDebounceLock após 500ms
    setTimeout(() => {
      this.bDebounceLock = false;
    }, 2000);

    createAndRedirect('folder', oFolder.fldr_nid)
      .then(() => {
        this.setState({ bIsProcessing: false });
      })
      .catch(oError => {
        this.setState({
          bIsProcessing: false,
          rcmpAlert: (
            <SweetAlert
              warning
              showCancel
              customClass="modal-edit modalMedium scrollBar"
              cancelBtnText={intl.get('close')}
              cancelBtnBsStyle="danger"
              onCancel={this.hideAlert}
              title={intl.get('create_document_error')}
              showConfirm={false}
            >
              {oError.response.data}
            </SweetAlert>
          )
        });
      });
  };

  renderFolderLayout = (oConfig, oFolderItem) => {

    const rcmpFolderContent = (
      <NavLink to={`/folder/${oFolderItem.fldr_nid}`} title={oFolderItem.fldr_cname}>
        <div className="folderindex-grid-folderIcon">
          <img className="folderindex-grid-folderIconImage" src={imgfFolderLarge} alt={oFolderItem.fldr_cname} />
        </div>
        <span className="folders-files">
          <span className="folderindex-grid-folderName folderName">{oFolderItem.fldr_cname}</span>
        </span>
      </NavLink>
    );

    return (
      <li key={oFolderItem.fldr_nid}>
        {!oConfig.bShowObsolete
          ? rcmpFolderContent
          : (
            <div className="archived-folder" title={oFolderItem.fldr_cname} >
              {rcmpFolderContent}
            </div>
          )}

        {hasModule('documents') && (oFolderItem.bCanCreate || can('admin')) && (
          <div className="right-menu">
            <span
              role="button"
              tabIndex="0"
              onKeyPress={evt => this.openFolderSubmenu(evt)}
              onClick={evt => this.openFolderSubmenu(evt)}
              title=""
              className="btn-menu"
            >
              <span className="insideP" />
            </span>
            {this.renderFolderSubmenu(oFolderItem)}
          </div>
        )}
      </li>
    );
  };

  renderDocumentLayout = (oConfig, oDocument) => {
    const oVersion = oDocument.oVersion;
    // todo: definir como será a exibição de documentos não publicados nos cards
    if (oVersion.stat_nid !== 4) return;

    const cTitle = oDocument.doc_ctitle || intl.get('DocumentDetails.documento_sem_titulo');

    return (
      <li key={oVersion.ver_nid}>
        <NavLink to={`/document/detail/${oVersion.ver_nid}`} >
          <div className="folderindex-grid-documentIcon">
            <img
              className="folderindex-grid-documentIconImage"
              src={imgfDocumentLarge}
            />
          </div>
          <div className="folderindex-grid-documentTitle">
            <h2 className="filterByName" title={cTitle}>
              {cTitle}
            </h2>
          </div>
          <div className="folderindex-grid-due">
            <h3 className="folderindex-text-due">
              {oVersion.dtReviewDate === null
                ? intl.get('FolderIndex.no_due')
                : formatDateToUserTZ(
                  oVersion.dtReviewDate,
                  `[${intl.get('FolderIndex.due_at')}] LL`
                )
              }
            </h3>
          </div>
        </NavLink>
        <div className="right-menu">
          <span
            role="button"
            tabIndex="0"
            onKeyPress={evt => this.openDocumentSubmenu(evt)}
            onClick={evt => this.openDocumentSubmenu(evt)}
            title=""
            className="btn-menu"
          >
            <span className="insideP" />
          </span>
          {this.renderDocumentSubmenu(oDocument)}
        </div>
      </li>
    );
  }

  renderEmptyResults = (aFoldersToShow, aDocumentsToShow, bLoading, bShowObsolete) => {
    if (aFoldersToShow.length || aDocumentsToShow.length || bLoading) return null;

    return (
      <p className="empty-folder">
        <strong>{intl.get('FolderIndex.sem_resultado_pastas')}</strong>
        {!bShowObsolete && intl.get('FolderIndex.sem_resultado_dica')}
      </p>
    );
  }

  fnHandlerToggleShowCards = prev => {
    this.setState({ bIsShowCards: !prev });
  };

  fnGetCardData = () => {
    const pendingType = {
      EDITING: 'Redação de documento',
      PUBLISHED: 'Publicação de documento',
      REVIEW: 'Revisão de documento'
    };
    let editing = { count: 0, data: [] };
    let pending = { count: 0, data: [] };
    let review = { count: 0, data: [] };
    let published = { count: 0, data: [] };
    Promise.all([
      Axios.get('/report/pending-per-user-by-status', {
        params: { nItemsPerPage: 2, cOrderBy: 'dUpdated', cOrderType: 'desc', cDocStatus: 'editing' }
      }).then(({ data }) => {
        editing = { count: data.total, data: data.data.slice(0, 2) };
      }),
      Axios.get('/report/pending-per-user-by-status', {
        params: { nItemsPerPage: 2, cOrderBy: 'dUpdated', cOrderType: 'desc', cDocStatus: 'review' }
      }).then(({ data }) => {
        review = { count: data.total, data: data.data.slice(0, 2) };
      }),
      Axios.get('/report/pending-per-user-by-status', {
        params: { nItemsPerPage: 2, cOrderBy: 'dUpdated', cOrderType: 'desc', cDocStatus: 'publishing' }
      }).then(({ data }) => {
        published = { count: data.total, data: data.data.slice(0, 2) };
      }),
      Axios.get('/read-card', {
        params: { cOrderBy: 'ver_dpublished', cOrderType: 'desc', perPage: 2 }
      }).then(({ data }) => {
        const dataPending = data.filter(doc => doc.nQuantityReaders !== doc.nQuantityUsers);
        pending = {
          count: dataPending.length,
          data: dataPending
        };
      })
    ]).then(() => {
      this.setState({ oFolderCards: { editing, pending, review, published } });
    });
  };

  render() {
    const {
      oFolderCards,
      rcmpAlert,
      bLoading,
      oFolder,
      aFolders, // trocar o nome do atributo no futuro
      nFolderId,
      aRecentDocuments,
      oConfig,
      aPages,
      aDocuments,
      bInternalLoad,
      aBreadcrumbFolders,
      aFiledDocuments,
      aArchivedFolders,
      bIsShowCards,
    } = this.state;
    const { getAlert } = this.props;

    const oLocalMoment = moment();
    oLocalMoment.locale(oLocalStorage.get('cLanguage'), oLocales[oLocalStorage.get('cLanguage')]);

    const aFoldersToShow = !oConfig.bShowObsolete ? aFolders : aArchivedFolders;
    const aDocumentsToShow = !oConfig.bShowObsolete ? aDocuments : aFiledDocuments;

    // todo: atualmente não parece estar funcionando, verificar
    const rcmpBreadcrumb =
      oFolder.fldr_nid && nFolderId ? (
        <Breadcrumb key={oFolder.fldr_nid} aFolders={aBreadcrumbFolders} />
      ) : (
        <Breadcrumb aPages={aPages} />
      );

    const rcmpCardHeader = this.generateCardHeader();

    let cClassName = 'v2-document ';
    if (oConfig.cViewType === 'grid') {
      cClassName += 'v2-document-grid';
    } else {
      cClassName += 'v2-document-list';
    }

    const bIsAdmin = oLocalStorage.get('bAdmin');
    const bCanShowCards = bIsAdmin || can(['pending-per-user', 'document-reading']);
    const cPageTitle = oFolder.fldr_nid === null ? intl.get('FolderIndex.documentos') : oFolder.fldr_cname;

    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={cPageTitle}
        cImage={documentImg}
        rcmpCardHeader={rcmpCardHeader}
        className={cClassName}
        cCurrentSideMenuOption={intl.get('Nav.document')}
      >
        {rcmpAlert}
        {getAlert()}

        <div className="clearfix" />
        <div>
          {bCanShowCards && (
            <div className="container-card">
              <div className="container-card__header">
                <button
                  type="button"
                  style={{ transform: !bIsShowCards ? 'rotate(180deg)' : null }}
                  onClick={() => this.fnHandlerToggleShowCards(bIsShowCards)}
                >
                  <Chevron isRotate={!bIsShowCards} />
                </button>
                <h2 style={{ fontWeight: 'bold' }}>Status dos Documentos</h2>
              </div>

              {bIsShowCards && (
                <div className="container-card__main">
                  <FolderCard
                    nCount={oFolderCards.editing.count}
                    cRedirectPath="/report/pending-per-user/editing/"
                    cTitle="Redação"
                    data={oFolderCards.editing.data}
                  />
                  <FolderCard
                    nCount={oFolderCards.review.count}
                    cRedirectPath="/report/pending-per-user/review"
                    cTitle="Revisão"
                    data={oFolderCards.review.data}
                  />
                  <FolderCard
                    nCount={oFolderCards.published.count}
                    cRedirectPath="/report/pending-per-user/publishing"
                    cTitle="Publicação"
                    data={oFolderCards.published.data}
                  />
                  <FolderCard
                    nCount={oFolderCards.pending.count}
                    cRedirectPath="/report/reading-document"
                    cTitle="Pendência de Leitura"
                    data={oFolderCards.pending.data}
                    isPending
                  />
                </div>
              )}
            </div>
          )}
          {!oConfig.bShowObsolete && (
            <div className="accessedRecently">
              {aRecentDocuments.length !== 0 && (
                <h2 className={oConfig.cViewType === 'list' ? 'includeMargin' : null} style={{ fontWeight: 'bold' }}>
                  {intl.get('FolderIndex.last_accessed')}
                </h2>
              )}
              <ul style={{ display: aRecentDocuments.length ? 'block' : 'none' }} className="document-list">
                {aRecentDocuments.map((oDocument) => (
                  <li
                    onClick={evt => this.docLink(evt, oDocument.published_version.ver_nid)}
                    role="presentation"
                    onKeyPress={evt => this.docLink(evt, oDocument.published_version.ver_nid)}
                    title={oDocument.doc_ctitle}
                    key={oDocument.doc_nid}
                  >
                    <div className="folderindex-grid-documentIcon">
                      <img
                        className="folderindex-grid-documentIconImage"
                        src={imgfDocumentLarge}
                        alt={oDocument.doc_ctitle}
                      />
                    </div>
                    <div className="folderindex-grid-documentTitle">
                      <h2 className="filterByName">{oDocument.doc_ctitle}</h2>
                    </div>
                    <div className="right-menu">
                      <span
                        role="button"
                        tabIndex="0"
                        onKeyPress={evt => this.openDocumentSubmenu(evt)}
                        onClick={evt => this.openDocumentSubmenu(evt)}
                        title=""
                        className="btn-menu"
                      >
                        <span className="insideP" />
                      </span>
                      {this.renderDocumentSubmenu(oDocument)}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {oConfig.cViewType === 'grid' ? (
            <>
              <div className="grid-view-container">
                {aDocumentsToShow.length !== 0 && (
                  <h2 style={{ fontWeight: 'bold', marginTop: '20px' }}>{intl.get('FolderIndex.pastas')}</h2>
                )}

                <ul
                  style={{ display: aFoldersToShow.length ? 'block' : 'none' }}
                  className={oConfig.cViewType === 'list' ? 'folder-list list-view' : 'folder-list'}
                >
                  {aFoldersToShow.map((oFolderItem) => this.renderFolderLayout(oConfig, oFolderItem))}
                </ul>
              </div>

              {aDocumentsToShow.length !== 0 && (
                <div className="grid-view-container">
                  <h2 style={{ fontWeight: 'bold', marginTop: '20px' }}>{intl.get('FolderIndex.documentos')}</h2>
                  <ul style={{ display: 'block' }} className="document-list list-shadow">
                    {aDocumentsToShow.map((oDocItem) => this.renderDocumentLayout(oConfig, oDocItem))}
                  </ul>
                </div>
              )}
            </>
          ) : (
            <FolderContentsList
              aDocuments={aDocumentsToShow}
              aFolders={aFoldersToShow}
              bShowArchived={oConfig.bShowObsolete}
              onStageNotAllowed={this.showDocumentStageModal}
              cSortHeader={oConfig.cOrderBy}
              cSortOrder={oConfig.cType}
              onSortingChange={this.setFilter}
              onDocumentEdit={(oDocument) => this.handleOpenDocumentEditModal(null, oDocument)}
              onDocumentDelete={(oDocument) => this.showDeleteDocument(null, oDocument)}
              onDocumentArchive={(oDocument) => this.showSendToFile(null, oDocument)}
              onDocumentRestore={(oDocument) => this.showRestoreModal(null, oDocument)}
            />
          )}
        </div>

        <div
          ref={(elDocList) => {
            this.setDocListObserverRef(elDocList);
            if (!this.oListObserver) this.createDocListOberserver();
          }}
          // ESSA HEIGHT PRECISA SER PELO MENOS 1PX OU O INFINITE SCROLL NÃO FUNCIONA
          style={{ height: '1px' }}
        />

        {bInternalLoad && <LoadingSpinner />}

        {this.renderEmptyResults(aFoldersToShow, aDocumentsToShow, bLoading, oConfig.bShowObsolete)}
      </Page>
    );
  }
}

export default FolderIndex;
