import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/pt-br';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { myPendingDocuments } from '../../actions/ActionDocumentPending';
import Page from '../default-v2/Page';
import Breadcrumb from '../../components/Breadcrumbs';
import CardHeader from '../default-v2/CardHeader';
import Filter from '../default-v2/Filter';
import './styles/report.scss';
import './styles/readingdocumentdelegates.scss';
import Axios from '../../config/Axios';
import IconStatus from '../../components/IconStatus';
import ReportTable from './ReportTable';
import { checkModule } from '../../config/Permissions';
import reportsImg from '../../assets/img/reports.svg';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

const oLocalStorage = new EncodedLocalStorage();
class EditsDocumentDelegates extends Component {
  constructor(oProps) {
    super(oProps);

    moment.locale(oLocalStorage.get('cLanguage'));

    this.state = {
      aPendingDocuments: [],
      bLoading: true,
      cSearch: '',
      cOrderBy: 'doc_ctitle',
      cOrderType: 'asc',
      aPages: [
        {
          cName: 'Nav.reports',
          cLink: undefined
        },
        {
          cName: 'EditsDocumentDelegates.breadcrumb_label',
          cLink: undefined
        }
      ]
    };
  }

  componentDidMount() {
    const { setAlert, history } = this.props;
    checkModule(history, 'documents');

    myPendingDocuments()
      .then(aPendingDocuments => this.setState({ bLoading: false, aPendingDocuments, bIsMyAccount: true }))
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError.aMsgErrors);
      });
  }

  handleClickTable = (evt, cNewOrderBy, cNewOrderType) => {
    this.setState(
      {
        cOrderBy: cNewOrderBy,
        cOrderType: cNewOrderType
      },
      () => {
        this.handleFilter(evt);
      }
    );
  };

  renderEditsDocumentDelegates = () => {
    const { aPendingDocuments } = this.state;

    if (aPendingDocuments !== undefined && aPendingDocuments.length === 0) {
      return (
        <>
          <p className="empty-result">
            <strong> {intl.get('EditsDocumentDelegates.empty_result')}</strong>
          </p>
        </>
      );
    }

    const rcmpEditsDocumentDelegates = Object.keys(aPendingDocuments).map(cKey => {
      const oInformation = aPendingDocuments[cKey];

      return (
        <tr
          key={cKey}
          onClick={() => this.openRoute(oInformation.cRoute, oInformation.ver_nid)}
          onKeyPress={() => this.openRoute(oInformation.cRoute, oInformation.ver_nid)}
        >
          <td title={oInformation.doc_ctitle} className="col-doc-name">
            <span>{oInformation.doc_ctitle}</span>
          </td>
          <td
            title={
              oInformation && oInformation.user_cname.length > 0
                ? oInformation.user_cname
                : ''
            }
            className="col-user-name"
          >
            <span>
              {oInformation && oInformation.user_cname.length > 0
                ? oInformation.user_cname
                : ''}
            </span>
          </td>
          <td>
            <IconStatus cStatus={oInformation.stat_cslugname} onClick={evt => { }} />
          </td>
          {/* <td>
            {oInformation.oVersion.ver_dcreated &&
              moment
                .parseZone(oInformation.oVersion.ver_dcreated)
                .tz(oLocalStorage.get('cTimezone'))
                .format('LL')}
          </td> */}
          <td>
            {moment
              .parseZone(oInformation.cDataDiffStandard)
              .tz(oLocalStorage.get('cTimezone'))
              .format('LL')}
          </td>
        </tr>
      );
    });

    return (
      <ReportTable
        aColumn={[
          {
            cName: intl.get('EditsDocumentDelegates.document'),
            cOrderBy: 'doc_ctitle',
            fnHandleClick: this.handleClickTable
          },
          {
            cName: intl.get('EditsDocumentDelegates.request_by'),
            cOrderBy: 'request_by',
            fnHandleClick: this.handleClickTable
          },
          {
            cName: intl.get('EditsDocumentDelegates.action'),
            cOrderBy: 'action',
            fnHandleClick: this.handleClickTable
          },
          // {
          //   cName: intl.get('EditsDocumentDelegates.published_at'),
          //   cOrderBy: 'ver_dcreated',
          //   fnHandleClick: this.handleClickTable
          // },
          {
            cName: intl.get('EditsDocumentDelegates.expiration_on'),
            cOrderBy: 'expiration_on',
            fnHandleClick: this.handleClickTable
          }
        ]}
      >
        {rcmpEditsDocumentDelegates}
      </ReportTable>
    );
  };

  openRoute = (cRoute, nVersionId) => {
    const { history } = this.props;
    if (cRoute === 'version.show') history.push(`/document/detail/${nVersionId}`);
    else if (cRoute === 'version.edit') history.push(`/document/editor/${nVersionId}`);
    else if (cRoute === 'version.review') history.push(`/document/review/${nVersionId}`);
    else if (cRoute === 'version.publish') history.push(`/document/publish/${nVersionId}`);
  };

  handleFilter = evt => {
    const { cSearch, cOrderBy, cOrderType } = this.state;
    const { setAlert } = this.props;

    Axios.get('my-account/pending-document-search', {
      params: {
        cSearch,
        cOrderBy,
        cOrderType
      }
    })
      .then(oResponse => {
        this.setState({
          aPendingDocuments: oResponse.data
        });
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
      });
  };

  handleNewFilter = (evt, oFilter) => {
    this.setState(
      {
        cSearch: oFilter.cText
      },
      () => {
        this.handleFilter(evt);
      }
    );
  };

  generateCardHeader = () => {
    const { history } = this.props;

    const rcmpFilter = <Filter fnOnType={this.handleNewFilter} />;

    const rcmpCardHeader = <CardHeader history={history} rcmpFilter={rcmpFilter} />;
    return rcmpCardHeader;
  };

  render() {
    const { bLoading, aPages } = this.state;
    const { getAlert } = this.props;

    const rcmpBreadcrumb = <Breadcrumb aPages={aPages} />;

    const rcmpCardHeader = this.generateCardHeader();

    const cPageTitle = intl.get('EditsDocumentDelegates.title');

    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={cPageTitle}
        cImage={reportsImg}
        rcmpCardHeader={rcmpCardHeader}
        className="v2-reading-document-delegates"
        cCurrentSideMenuOption={intl.get('Nav.report')}
      >
        {getAlert()}
        {this.renderEditsDocumentDelegates()}
      </Page>
    );
  }
}

export default EditsDocumentDelegates;
