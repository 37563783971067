import React, { Component } from 'react';
import './styles/header.scss';

class NavItem extends Component {
  renderSubitems = () => {
    const { aSubitems } = this.props;
    if (typeof aSubitems === 'undefined' || aSubitems.length <= 0) {
      return null;
    }

    let rcmpSubitems = '<ul>';
    rcmpSubitems += Object.keys(aSubitems).map(cKey => aSubitems[cKey]);
    rcmpSubitems += '</ul>';
    return rcmpSubitems;
  };

  render = () => {
    const {
      cIcon,
      cText,
      cLink,
      bIsSubitem,
      bHasAlert,
      id,
      onClick,
      className,
      cIconFocus,
      children,
      bShowItem = true
    } = this.props;
    const aRenderedSubitems = this.renderSubitems();
    let cClassName = `v2-navitem ${className}`;
    let rcmpElement = '';

    if (cIconFocus.length > 0 && cIcon.length > 0) {
      rcmpElement = (
        <a href={cLink} onClick={onClick}>
          <img
            src={cIcon}
            alt={cText}
            className="v2-navitem-icon"
            onFocus={evt => {
              evt.currentTarget.src = cIconFocus;
            }}
            onMouseOver={evt => {
              evt.currentTarget.src = cIconFocus;
            }}
            onBlur={evt => {
              evt.currentTarget.src = cIcon;
            }}
            onMouseOut={evt => {
              evt.currentTarget.src = cIcon;
            }}
          />
        </a>
      );
    } else if (cIcon.length > 0) {
      rcmpElement = (
        <a href={cLink} onClick={onClick}>
          <img src={cIcon} alt={cText} className="v2-navitem-icon" />
        </a>
      );
    } else {
      rcmpElement = (
        <a href={cLink} className="v2-navitem-text" onClick={onClick}>
          {cText}
        </a>
      );
    }


    if (bIsSubitem) {
      cClassName = 'v2-navitem-subitem';
    }

    if (bHasAlert) {
      cClassName = ' new-notifications';
    }

    return (
      <li id={id} className={cClassName} style={{ display: !bShowItem ? 'none' : 'unset' }}>
        {rcmpElement}
        {aRenderedSubitems}
        {children}
      </li>
    );
  };
}

NavItem.defaultProps = {
  className: '',
  cIcon: '',
  cIconFocus: '',
  cText: '',
  cLink: null,
  bIsSubitem: false,
  bHasAlert: false
};
export default NavItem;
