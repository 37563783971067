import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import { toast } from '@stardust-ds/react';
import classNames from 'classnames';
import Page from '../default-v2/Page';
import Breadcrumb from '../../components/Breadcrumbs';
import intl from 'react-intl-universal';
import userImg from '../../assets/img/users.svg';
import CardHeader from '../default-v2/CardHeader';
import Axios from '../../config/Axios';
import imgfSelectDown from '../default-v2/img/select-down.svg';
import imgfSelectUp from '../default-v2/img/select-up.svg';
import { can } from '../../config/Permissions';
import { formatDateToUserTZ } from '../../utils/time';
import oClasses from '../operational-panel/MigrateResponsibilityOperational.module.scss'; // usa o mesmo css
import { SelectResponsibleModal } from '../operational-panel/MigrateResponsibilityOperational';

const MigrateResponsibilityDocument = ({ match }) => {
  const [oConfig, setConfig] = useState(
    JSON.parse(localStorage.getItem('cConfig')) || { cOrderBy: 'cType', cType: 'asc' }
  );
  const [oMigrateModalOptions, setMigrateModalOptions] = useState({ bShow: false });
  const [bIsLoading, setIsLoading] = useState(true);
  const [bHasNextPage, setHasNextPage] = useState(true);
  const [nNextPage, setNextPage] = useState(1);
  const [aUsers, setUsers] = useState([]);
  const [aDocuments, setDocuments] = useState([]);
  const [aSelectedDocuments, setSelectedDocuments] = useState([]);

  const oCheckAllCbRef = useRef(null);
  const oObserverRef = useRef(null);
  const fnLastItemRef = useCallback((elLastItem) => {
    if (bIsLoading) return;
    if (oObserverRef.current) {
      oObserverRef.current.disconnect();
    }
    if (!bHasNextPage) return;

    oObserverRef.current = new IntersectionObserver(([oEntry]) => {
      if (!oEntry.isIntersecting) return;
      getResponsibilities();
    }, {
      threshold: 0,
      rootMargin: '0px 200px 0px 200px',
    });

    if (elLastItem) oObserverRef.current.observe(elLastItem);
  }, [bIsLoading, bHasNextPage]);

  const nUserId = +match.params.nId;

  // atualiza a lista quando altera a ordenação
  useEffect(() => {
    if (bHasNextPage) {
      getResponsibilities(true);
      setSelectedDocuments([]);
      uncheckCheckAllCb();
      return;
    }
    const aNewDocuments = [...aDocuments];
    sortDocuments(aNewDocuments, oConfig.cOrderBy, oConfig.cType === 'asc');
    setDocuments(aNewDocuments);
  }, [oConfig.cOrderBy, oConfig.cType]);

  useEffect(() => {
    if (!can('admin')) {
      window.location.href = '/';
      return;
    }
    Axios.get(`/user/all`).then((oResponse) => {
      const aTargetUsers = oResponse.data.aUsers
        .filter(item => item.user_nid !== nUserId)
        .map(oUser => ({ value: oUser.user_nid, label: oUser.user_cname }));
      setUsers(aTargetUsers);
    });
  }, []);

  const getResponsibilities = async (bResetList = false) => {
    try {
      setIsLoading(true);

      const oParams = new URLSearchParams({
        orderBy: oConfig.cOrderBy,
        orderDir: oConfig.cType,
        page: bResetList ? 1 : nNextPage
      });

      const oResponse = await Axios.get(`/document/get-responsibilities/${nUserId}?${oParams.toString()}`);
      /** @type {DocumentResponsibility[]} */
      const aNewDocuments = oResponse.data.aResponsibilities;
      const bHasMore = oResponse.data.bHasNextPage;

      setHasNextPage(bHasMore);
      if (bHasMore) {
        setNextPage((nCurrPage) => bResetList ? 2 : nCurrPage + 1);
      }
      setDocuments(bResetList
        ? aNewDocuments
        : [...aDocuments, ...aNewDocuments]
      );
      uncheckCheckAllCb();
    } catch (oError) {
      //
    } finally {
      setIsLoading(false);
    }
  };

  const migrateDocuments = async (nNewUser, cPassword, bIsMigrateAll) => {
    // otimização simples para reduzir tamanho da requisição e acelerar a migração:
    // quando todas as responsabilidades forem selecionadas, trata como migrar todas
    const bMigrateAll = bIsMigrateAll
      || (!bHasNextPage && aSelectedDocuments.length === aDocuments.length);

    await Axios.post('/document/migrate-responsibilities', {
      nOldUser: nUserId,
      nNewUser,
      cPassword,
      // reduzir tamanho da request: quando bMigrateAll === true, campo é ignorado no backend
      aDocumentIds: !bMigrateAll ? aSelectedDocuments : [],
      bMigrateAll,
    });
    setMigrateModalOptions(() => ({ bShow: false }));
    getResponsibilities(true);
    setSelectedDocuments([]);
    toast({
      type: 'success',
      title: intl.get('FolderIndex.success_migration'),
    });
  };

  const updateSorting = (cColumn) => {
    setConfig((oPrevConfig) => {
      const oNewConfig = {
        ...oPrevConfig,
        ...(oConfig.cOrderBy !== cColumn
          ? { cOrderBy: cColumn }
          : { cType: oPrevConfig.cType === 'asc' ? 'desc' : 'asc' }
        ),
      };
      localStorage.setItem('cConfig', JSON.stringify(oNewConfig));
      return oNewConfig;
    });
  };

  const handleOpenMigrateModal = (bMigrateAll = false) => {
    setMigrateModalOptions((oPrevOptions) => ({
      ...oPrevOptions,
      bShow: true,
      bMigrateAll,
    }));
  };

  const handleCloseMigrateModal = () => {
    setMigrateModalOptions(() => ({ bShow: false }));
  }

  const handleCheckAll = useCallback((evtClick) => {
    const bIsChecked = evtClick.target.checked;
    const aNewSelectedDocs = bIsChecked ? aDocuments.map(({nId}) => nId) : [];
    setSelectedDocuments(aNewSelectedDocs);
  }, [aDocuments, setSelectedDocuments]);

  const uncheckCheckAllCb = () => {
    const elCheckAllCb = oCheckAllCbRef.current;
    if (elCheckAllCb) elCheckAllCb.checked = false;
  }

  const handleDocumentCheck = (evtClick, oDocument) => {
    const bIsChecked = evtClick.target.checked;
    const nDocId = oDocument.nId;
    if (bIsChecked) {
      setSelectedDocuments((aSelDocs) => [...aSelDocs, nDocId]);
    } else {
      setSelectedDocuments((aSelDocs) => aSelDocs.filter((aSelDocs) => aSelDocs !== nDocId));
      uncheckCheckAllCb();
    }
  }

  const aPages = [
    { cName: 'user', cLink: '/user' },
    { cName: 'modulo_documentos', cLink: '/folder' },
    { cName: 'User.migrate_responsibilities' },
  ];
  return (
      <Page
        loading={0}
        rcmpBreadcrumb={<Breadcrumb aPages={aPages} />}
        cTitle={intl.get('User.migrate_responsibilities')}
        cImage={userImg}
        rcmpCardHeader={<CardHeader bIsItToInsertGoBackBtn windowHistory />}
        className="v2-document"
        cCurrentSideMenuOption={intl.get('Nav.document')}
      >
        <div style={{ height: '64vh', overflowY: 'auto' }}>
          <ResponsibilitiesListHeader
            onCheckAll={handleCheckAll}
            onUpdateOrder={updateSorting}
            oConfig={oConfig}
            oCheckAllCbRef={oCheckAllCbRef}
          />
          <ul style={{ display: 'block' }} className="document-list list-shadow list-view">
            {aDocuments.map((oDocument, nIdx) => (
              <ResponsibilitiesListItem
                key={oDocument.nId}
                ref={nIdx + 1 === aDocuments.length ? fnLastItemRef : null}
                oDocument={oDocument}
                onCheck={handleDocumentCheck}
                bIsChecked={aSelectedDocuments.includes(oDocument.nId)}
              />
            ))}
            {bIsLoading && (
              <li className="centered-text">
                <div className="spinner" style={{ width: '50px', height: '50px' }} />
                <br />
                {intl.get('carregando')}
              </li>
            )}
          </ul>
        </div>
        <div className="button-stack">
          <Button
            type="button"
            className="btn break-line-btn"
            onClick={() => handleOpenMigrateModal(true)}
          >
            {intl.get('FolderIndex.migrate')} <br /> {intl.get('User.all')}
          </Button>
          <Button
            type="button"
            className="btn break-line-btn"
            disabled={aSelectedDocuments.length < 1}
            onClick={() => handleOpenMigrateModal()}
          >
            {intl.get('FolderIndex.migrate')} <br /> {intl.get('FolderIndex.selecteds')}
          </Button>
        </div>
        {oMigrateModalOptions.bShow && (
          <SelectResponsibleModal
            onClose={handleCloseMigrateModal}
            aUsers={aUsers}
            onSubmit={migrateDocuments}
            bMigrateAll={oMigrateModalOptions.bMigrateAll}
          />
        )}
      </Page>
  );
};

const ResponsibilitiesListHeader = ({
  onCheckAll = () => {},
  onUpdateOrder = () => {},
  oConfig = {},
  oCheckAllCbRef,
}) => {
  const { cOrderBy, cType: cOrderType } = oConfig;
  const cIcon = cOrderType === 'asc' ? imgfSelectUp : imgfSelectDown;

  return (
    <div className={classNames("filterList", "noselect", oClasses['migrate-list'])}>
      <span role="button" tabIndex="0" className="alt-checkbox-v2">
        <input type="checkbox" onChange={onCheckAll} ref={oCheckAllCbRef} />
      </span>{' '}
      <span
        role="button"
        tabIndex="0"
        onClick={() => onUpdateOrder('cName')}
        className={classNames('filterByName-v2', cOrderBy === 'cName' && 'active')}
      >
        {intl.get('name')}
        {cOrderBy === 'cName' && (
          <img className="folderindex-list-icon" src={cIcon} alt={cOrderType} />
        )}
      </span>
      <span
        role="button"
        tabIndex="0"
        onClick={() => onUpdateOrder('cPublishedDate')}
        className={classNames('filterByPublish', cOrderBy === 'cPublishedDate' && 'active')}
      >
        {intl.get('FolderIndex.published_at')}
        {cOrderBy === 'cPublishedDate' && (
          <img className="folderindex-list-icon" src={cIcon} alt={cOrderType} />
        )}
      </span>
      <span
        role="button"
        tabIndex="0"
        onClick={() => onUpdateOrder('dtReviewDate')}
        className={classNames('filterByDue', cOrderBy === 'dtReviewDate' && 'active')}
      >
        {intl.get('FolderIndex.finish_at')}
        {cOrderBy === 'dtReviewDate' && (
          <img className="folderindex-list-icon" src={cIcon} alt={cOrderType} />
        )}{' '}
      </span>
      <span className="filterByResponsible-v2" style={{ cursor: 'default' }}>
        {intl.get('FolderIndex.responsibilities')}
      </span>
      <span
        role="button"
        tabIndex="0"
        onClick={() => onUpdateOrder('bIsSigned')}
        className={classNames('filterByEnvelope', cOrderBy === 'bIsSigned' && 'active')}
      >
        {intl.get('FolderIndex.docusign_signature')}
        {cOrderBy === 'bIsSigned' && (
          <img className="folderindex-list-icon" src={cIcon} alt={cOrderType} />
        )}{' '}
      </span>
    </div>
  );
};

const ResponsibilitiesListItem = forwardRef(({
  oDocument,
  onCheck = () => {},
  bIsChecked,
}, ref) => {

  const cDisplayTitle = useMemo(() => {
    return oDocument.cName || intl.get('DocumentDetails.documento_sem_titulo');
  }, [oDocument.cName]);

  return (
    <li role="presentation" ref={ref}>
      <h2 className="alt-checkbox-v2 ">
        <input
          type="checkbox"
          onChange={(evtChange) => onCheck(evtChange, oDocument)}
          checked={bIsChecked}
        />
      </h2>

      <h2 className="filterByName-v2" title={cDisplayTitle}>
        {cDisplayTitle}
      </h2>

      <p className="filterByPublish">
        {oDocument.cPublishedDate
            ? formatDateToUserTZ(oDocument.cPublishedDate, 'LL')
            : ''}
      </p>

      <p className="filterByDue">
        {oDocument.dtReviewDate
          ? formatDateToUserTZ(oDocument.dtReviewDate, 'LL')
          : intl.get('FolderIndex.no_due')}
      </p>

      <p className="filterByResponsible-v2" style={{ display: 'flex', textAlign: 'center' }}>
        <ResponsibilitiesColumn oDocument={oDocument} />
      </p>

      <p className="filterByPublish" style={{ textAlign: 'center' }}>
        {oDocument.bIsSigned ? intl.get('sim') : intl.get('nao')}
      </p>
    </li>
  )
});

const ResponsibilitiesColumn = ({oDocument = {}}) => {
  const aResponsibilities = useMemo(() => {
    const aLabels = [];
    if (oDocument.bIsWriter) {
      aLabels.push(intl.get('FolderIndex.responsibility_write'));
    }
    if (oDocument.bIsReviewer) {
      aLabels.push(intl.get('FolderIndex.responsibility_review'));
    }
    if (oDocument.bIsResponsible) {
      aLabels.push(intl.get('FolderIndex.responsibility_publish'));
    }
    return aLabels;
  }, [oDocument]);

  return (
    <span style={{ textWrap: 'nowrap' }}>
      {aResponsibilities.join(' / ')}
    </span>
  );
};


/**
 * Ordena e retorna a lista de responsabilidades. **Modifica o array passado como parâmetro.**
 * @param {DocumentResponsibility[]} aDocuments
 * @param {"cName"|"cPublishedDate"|"dtReviewDate"|"bIsSigned"} cColumn
 * @param {boolean} bAscending
 */
const sortDocuments = (aDocuments, cColumn, bAscending) => {
  const nSign = bAscending ? 1 : -1;

  if (cColumn === 'bIsSigned') {
    return aDocuments.sort((a, b) => nSign * (Number(a[cColumn]) - Number(b[cColumn])) );
  }
  return aDocuments.sort((a, b) => nSign * (a[cColumn] ?? '').localeCompare(b[cColumn] ?? ''));
};

/**
 * @typedef {object} DocumentResponsibility
 *
 * @property {number} nId
 * @property {string} cName
 * @property {string} cPublishedDate
 * @property {string} dtReviewDate
 * @property {boolean} bIsWriter
 * @property {boolean} bIsReviewer
 * @property {boolean} bIsResponsible
 * @property {boolean} bIsSigned
 */


export default MigrateResponsibilityDocument;
