import React, { Component } from 'react';
import intl from 'react-intl-universal';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/pt-br';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import Axios from '../../config/Axios';
import RequestModal from '../request/RequestModal';
import NewVersion from '../document/NewVersion';
import Document from '../document/Document';
import ScrollBar from '../../components/ScrollBar';
import Page from '../default-v2/Page';
import Breadcrumb from '../../components/Breadcrumbs';
import AltCheckbox from '../../components/AltCheckbox';
import CardHeader from '../default-v2/CardHeader';
import Filter from '../default-v2/Filter';
import url from '../../config/Url';
import imgfNoPic from '../../assets/img/nopic.svg';
import './styles/request.scss';
import ReportTable from './ReportTable';
import { checkModule } from '../../config/Permissions';
import { createAndRedirect } from '../../actions/ActionDocumentEditor';
import reportsImg from '../../assets/img/reports.svg';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

const oLocalStorage = new EncodedLocalStorage();
class Request extends Component {
  constructor(oProps) {
    super(oProps);
    moment.locale(oLocalStorage.get('cLanguage'));
    this.state = {
      bIsLoad: true,
      aRequest: [],
      cSearch: '',
      cOrderBy: 'send_by',
      cOrderType: 'asc',
      bShowFinished: false,
      aPages: [
        {
          cName: 'Nav.reports',
          cLink: undefined
        },
        {
          cName: 'Request.breadcrumb_label',
          cLink: undefined
        }
      ]
    };
  }

  componentDidMount = () => {
    const { match, history } = this.props;
    checkModule(history, 'documents');

    Axios.get('my-account/pending-request').then(oResponse => {
      const aRequest = oResponse.data;
      let oRequest;

      if (match.path !== '/report/request') {
        const cId = match.params.nRequestId;
        if (cId !== null && cId !== undefined)
          oRequest = this.getRequestByPath(aRequest, match.path, Number(match.params.nRequestId));
      }

      this.setState(
        {
          aRequest,
          bIsLoad: false
        },
        () => {
          if (oRequest !== undefined) this.openModalRequest(oRequest);
        }
      );
    });
  };

  getRequestByPath = (aRequest, cPath, nReqId) => {
    return aRequest.find(oRequestItem => oRequestItem.rqst_nid === nReqId);
  };

  getRequest = () => {
    const { setAlert } = this.props;
    Axios.get('my-account/pending-request')
      .then(oResponse => {
        this.setState({
          aRequest: oResponse.data
        });
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
      });
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
    this.getRequest();
  };

  successMessage = cMsg => {
    const { setAlert } = this.props;
    this.hideAlert();
    setAlert('success', cMsg);
    this.componentDidMount();
  };

  openModalRequest = oRequest => {
    const { history, setAlert } = this.props;

    this.setState({
      rcmpAlert: (
        <RequestModal
          oRequest={oRequest}
          onSuccess={this.successMessage}
          hideAlert={this.hideAlert}
          history={history}
          setAlert={setAlert}
        />
      )
    });
  };

  handleChangeState = (evtPending) => {
    const { name, checked } = evtPending.target;
    this.setState({
      [name]: checked
    });
  };



  openModalNewVersion = oRequest => {
    const { setAlert, history } = this.props;
    Axios.get(`version/validate/new-version/${oRequest.doc_nid}`)
      .then(oResponse => {
        this.setState({
          rcmpAlert: (
            <SweetAlert customClass="modal-edit md scrollBar" title="" onConfirm={this.hideAlert} showConfirm={false}>
              <div className="head-modal">
                {intl.get('Dashboard.nova_versao')}
                <span
                  aria-labelledby="head-modal"
                  tabIndex={0}
                  onClick={this.hideAlert}
                  className="close"
                  role="button"
                  onKeyPress={this.hideAlert}
                />
              </div>
              <ScrollBar>
                <NewVersion
                  nRequestId={oRequest.rqst_nid}
                  onSuccess={this.successMessage}
                  closeModal={this.hideAlert}
                  history={history}
                />
              </ScrollBar>
            </SweetAlert>
          )
        });
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
      });
  };

  openRequest = oRequest => {
    this.openModalRequest(oRequest);
  };

  openCreateDocumentModal = nRequestId => {
    createAndRedirect('request', nRequestId).catch(oError => {
      this.setState({
        rcmpAlert: (
          <SweetAlert
            warning
            showCancel
            customClass="modal-edit modalMedium scrollBar"
            cancelBtnText={intl.get('close')}
            cancelBtnBsStyle="danger"
            onCancel={this.hideAlert}
            title={intl.get('create_document_error')}
            showConfirm={false}
          >
            {oError.response.data}
          </SweetAlert>
        )
      });
    });
  };

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handleFilter = evt => {
    const { setAlert } = this.props;
    const { cSearch, cOrderBy, cOrderType } = this.state;

    Axios.get('my-account/pending-request-search', {
      params: {
        cSearch,
        cOrderBy,
        cOrderType
      }
    })
      .then(oResponse => {
        this.setState({ aRequest: oResponse.data });
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
      });
  };

  handleNewFilter = (evt, oFilter) => {
    this.setState(
      {
        cSearch: oFilter.cText
      },
      () => {
        this.handleFilter(evt);
      }
    );
  };

  generateCardHeader = (bShowFinished) => {
    const { history } = this.props;

    const aCheckboxes = [];
    const rcmpFinishedSwitch = (
      <AltCheckbox
        key="checkbox-pending-request"
        onChange={this.handleChangeState}
        title={intl.get('Request.completed')}
        label={intl.get('Request.completed')}
        name="bShowFinished"
        localeLabel="end"
        checked={bShowFinished}
      />
    );
    aCheckboxes.push(rcmpFinishedSwitch);

    const rcmpFilter = <Filter fnOnType={this.handleNewFilter} aCheckboxes={aCheckboxes} />;

    const rcmpCardHeader = <CardHeader history={history} rcmpFilter={rcmpFilter} />;
    return rcmpCardHeader;
  };

  handleClickTable = (evt, cNewOrderBy, cNewOrderType) => {
    this.setState(
      {
        cOrderBy: cNewOrderBy,
        cOrderType: cNewOrderType
      },
      () => {
        this.handleFilter(evt);
      }
    );
  };

  renderRequestList() {
    const { aRequest, bShowFinished } = this.state;

    const aReqFiltered = bShowFinished
      ? aRequest.filter(aRequFltrStatus => ['accepted', 'rejected'].includes(aRequFltrStatus.stat_cslugname))
      : aRequest.filter(aRequFltrStatus => aRequFltrStatus.stat_cslugname === 'pending');


    if (aReqFiltered !== undefined && aReqFiltered.length === 0) {
      return (
        <>
          <p className="empty-result">
            <strong> {intl.get('Request.empty_result')}</strong>
          </p>
        </>
      );
    }

    const rcmpReadingDocumentDelegates = Object.keys(aReqFiltered).map(cKey => {
      const oInformation = aReqFiltered[cKey];

      return (
        <tr
          key={cKey}
          onKeyPress={() => this.openRequest(oInformation)}
          onClick={() => this.openRequest(oInformation)}
          className="row-requests"
        >
          <td>
            <div className="row-pending-requests-user">
              <img
                src={
                  oInformation.requester_cavatar
                    ? oInformation.requester_cavatar
                    : imgfNoPic
                }
                alt={oInformation.requester_cname}
              />
              <p title={oInformation.requester_cname}>{oInformation.requester_cname}</p>
            </div>
          </td>
          <td>
            <div className="row-pending-requests-user">
              <img
                src={
                  oInformation.destiny_cavatar
                    ? oInformation.destiny_cavatar
                    : imgfNoPic
                }
                alt={oInformation.destiny_cname}
              />
              <p title={oInformation.destiny_cname}>{oInformation.destiny_cname}</p>
            </div>
          </td>
          <td style={{ whiteSpace: 'pre-wrap' }} title={oInformation.rqst_cjustification} className="col-desc">
            <span>{oInformation.rqst_cjustification}</span>
          </td>
          <td>
            <span>{oInformation.stat_cname}</span>
          </td>
          <td>
            {moment
              .parseZone(oInformation.rqst_drequestedupdated)
              .tz(oLocalStorage.get('cTimezone'))
              .format('LL')}
          </td>
        </tr>
      );
    });

    return (
      <ReportTable
        aColumn={[
          { cName: intl.get('Request.send_by'), cOrderBy: 'send_by', fnHandleClick: this.handleClickTable },
          { cName: intl.get('Request.receive_by'), cOrderBy: 'receive_by', fnHandleClick: this.handleClickTable },
          { cName: intl.get('Request.activity'), cOrderBy: 'activity', fnHandleClick: this.handleClickTable },
          { cName: intl.get('Request.status_pending'), cOrderBy: 'status_pending', fnHandleClick: this.handleClickTable },
          { cName: intl.get('Request.send_in'), cOrderBy: 'send_in', fnHandleClick: this.handleClickTable },
        ]}
      >
        {rcmpReadingDocumentDelegates}
      </ReportTable>
    );
  }

  render() {
    const { rcmpAlert, bIsLoad, aPages, bShowFinished } = this.state;
    const { getAlert } = this.props;

    const rcmpBreadcrumb = <Breadcrumb aPages={aPages} />;

    const rcmpCardHeader = this.generateCardHeader(bShowFinished);

    const cPageTitle = intl.get('Request.page_title');

    return (
      <Page
        loading={bIsLoad ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={cPageTitle}
        cImage={reportsImg}
        rcmpCardHeader={rcmpCardHeader}
        className="v2-reading-document-responsible"
        cCurrentSideMenuOption={intl.get('Nav.report')}
      >
        {rcmpAlert}
        {getAlert()}

        {this.renderRequestList()}
      </Page>
    );
  }
}

export default Request;
