import React, { useEffect } from 'react';

const ScriptLoader = () => {
  const cPathName = window.location.pathname;

  useEffect(() => {
    const aExcludeRoutes = [
      '/login',
      '/pre-register/',
      /\/customer\/resend-activation\/[^/]+/,
      /\/customer\/register\/[^/]+/,
      /\/customer\/accept\/[^/]+/,
      /\/database\/create\/[^/]*?/,
      '/reset-password',
      /\/reset-password\/[^/]+/,
      /\/reset-email\/[^/]+/,
      /\/register\/activate\/[^/]+/,
      /\/register\/resend-activation\/[^/]+/
    ];

    const bIsExcluded = aExcludeRoutes.some(route =>
      typeof route === 'string' ? route === cPathName : route.test(cPathName)
    );

    if (bIsExcluded) {
      return; // Não carrega os scripts nessas rotas
    }

  }, [cPathName]); // Adicione `user` como dependência

  return null;
};

export default ScriptLoader;
