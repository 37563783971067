import Axios from '../config/Axios';

export default function pendingPerUser(nPage = 1, nItemsPerPage = 50) {
  return Axios.get('/report/pending-per-user', {
    params: {
      nPage,
      nItemsPerPage
    }
  }).then(oResponse => oResponse.data);
}

export function searchPendingPerUserStatus(cSearch, cOrderBy, cOrderType, nPage = 1, nItemsPerPage = 50, cDocStatus, oFilterReport) {
  const { aFilterUsers, cFilterType } = oFilterReport;

  return Axios.get('/report/pending-per-user-by-status', {
    params: {
      cSearch,
      cOrderBy,
      cOrderType,
      nPage,
      nItemsPerPage,
      cDocStatus,
      aFilterUsers,
      cFilterType
    }
  }).then(oResponse => oResponse.data);
}

export function searchPendingPerUser(cSearch, cOrderBy, cOrderType, nPage = 1, nItemsPerPage = 50, cDocStatus, oFilterReport) {
  const { aFilterUsers, cFilterType } = oFilterReport;
  return Axios.get('/report/pending-per-user', {
    params: {
      cSearch,
      cOrderBy,
      cOrderType,
      nPage,
      nItemsPerPage,
      cDocStatus,
      aFilterUsers,
      cFilterType
    }
  }).then(oResponse => oResponse.data);
}
