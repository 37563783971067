import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/pt-br';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { myPendingReadings } from '../../actions/ActionReadPending';
import Page from '../default-v2/Page';
import Breadcrumb from '../../components/Breadcrumbs';
import Pagination from 'react-js-pagination';
import CardHeader from '../default-v2/CardHeader';
import Filter from '../default-v2/Filter';
import './styles/report.scss';
import './styles/readingdocumentdelegates.scss';
import './styles/v2-reports.scss';
import Axios from '../../config/Axios';
import ReportTable from './ReportTable';
import { checkModule } from '../../config/Permissions';
import reportsImg from '../../assets/img/reports.svg';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';
import PaginationMenu from '../default-v2/PaginationMenu';

const oLocalStorage = new EncodedLocalStorage();
class ReadingDocumentDelegates extends Component {
  constructor(oProps) {
    super(oProps);

    moment.locale(oLocalStorage.get('cLanguage'));

    this.state = {
      aPendingReads: [],
      bLoading: true,
      cSearch: '',
      cOrderBy: 'doc_ctitle',
      cOrderType: 'asc',
      aPages: [
        {
          cName: 'Nav.reports',
          cLink: undefined
        },
        {
          cName: 'ReadingDocumentDelegates.breadcrumb_label',
          cLink: undefined
        }
      ],
      nCurrentPage: 1,
      nTotalPages: 0,
      nItemsPerPage: 50,
      nTotal: 0,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    checkModule(history, 'documents');
    this.fetchData();
  }

  getPending = (nPage, nPerPage, orderBy, order, cSearch) => {
    return myPendingReadings(nPage, nPerPage, orderBy, order, cSearch);
  };

  fetchData = () => {
    const { setAlert, clearAlert, match } = this.props;
    const { nCurrentPage, nItemsPerPage, cOrderBy, cOrderType } = this.state;

    this.setState({ bLoading: true });

    this.getPending(nCurrentPage, nItemsPerPage, cOrderBy, cOrderType)
      .then(aPendingReads => {
        this.setState({
          bLoading: false,
          aPendingReads: aPendingReads.data,
          nTotalPages: aPendingReads.last_page,
          nTotal: aPendingReads.total
        });
      })
      .catch(oError => {
        this.setState({ bLoading: false, aPendingReads: [] });
        setAlert('error', oError.aMsgErrors);
      });
  };

  handlePageChange = nPageNumber => {
    this.setState({ nCurrentPage: nPageNumber }, this.fetchData);
  };

  handleClickTable = (evt, cNewOrderBy, cNewOrderType) => {
    this.setState(
      {
        cOrderBy: cNewOrderBy,
        cOrderType: cNewOrderType
      },
      () => {
        this.handleFilter(evt);
      }
    );
  };

  renderReadingDocumentDelegates = () => {
    const { aPendingReads } = this.state;
    const { history } = this.props;

    if (aPendingReads !== undefined && aPendingReads.length === 0) {
      return (
        <>
          <p className="empty-result">
            <strong> {intl.get('ReadingDocumentDelegates.empty_result')}</strong>
          </p>
        </>
      );
    }

    const rcmpReadingDocumentDelegates = Object.keys(aPendingReads).map(cKey => {
      const oInformation = aPendingReads[cKey];

      return (
        <tr
          key={cKey}
          onKeyPress={evt => {
            history.push(`/document/detail/${oInformation.published_version.ver_nid}`);
          }}
          onClick={evt => {
            history.push(`/document/detail/${oInformation.ver_nid}`);
          }}
        >
          <td title={oInformation.doc_ctitle} className="col-doc-name">
            <span>{oInformation.doc_ctitle}</span>
          </td>
          <td
            title={
              oInformation.user_cname && oInformation.user_cname.length > 0
                ? oInformation.user_cname
                : ''
            }
            className="col-user-name"
          >
            <span>
              {oInformation.user_cname && oInformation.user_cname.length > 0
                ? oInformation.user_cname
                : ''}
            </span>
          </td>
          <td>
            <i className="icon-reading" title={intl.get('Status.awaiting_reading')} />
          </td>
          <td>
            {oInformation.ver_dpublished && (
              <>
                {moment
                  .parseZone(oInformation.ver_dpublished)
                  .tz(oLocalStorage.get('cTimezone'))
                  .format('LL')}
              </>
            )}
          </td>
        </tr>
      );
    });

    return (
      <ReportTable
        aColumn={[
          {
            cName: intl.get('ReadingDocumentDelegates.document'),
            cOrderBy: 'doc_ctitle',
            fnHandleClick: this.handleClickTable
          },
          {
            cName: intl.get('ReadingDocumentDelegates.request_by'),
            cOrderBy: 'request_by',
            bIsDisable: true,
            fnHandleClick: this.handleClickTable
          },
          {
            cName: intl.get('ReadingDocumentDelegates.action'),
            cOrderBy: 'acc_version.stat_nid',
            fnHandleClick: this.handleClickTable
          },
          {
            cName: intl.get('ReadingDocumentDelegates.published_at'),
            cOrderBy: 'acc_version.ver_dpublished',
            fnHandleClick: this.handleClickTable
          }
        ]}
      >
        {rcmpReadingDocumentDelegates}
      </ReportTable>
    );
  };

  handleFilter = evt => {
    const { cSearch, cOrderBy, cOrderType, nCurrentPage, nItemsPerPage } = this.state;
    const { setAlert, clearAlert } = this.props;

    this.getPending(nCurrentPage, nItemsPerPage, cOrderBy, cOrderType, cSearch)
      .then(aPendingReads => {
        this.setState({
          aPendingReads: aPendingReads.data,
          nTotalPages: aPendingReads.last_page,
          nTotal: aPendingReads.total
        });
        clearAlert();
      })
      .catch(oError => {
        this.setState({ bLoading: false, aPendingReads: -1 });
        setAlert('error', oError.aMsgErrors);
      });
  };

  handleNewFilter = (evt, oFilter) => {
    this.setState(
      {
        cSearch: oFilter.cText,
        cOrderType: oFilter.cOrderType
      },
      () => {
        this.handleFilter(evt);
      }
    );
  };

  generateCardHeader = () => {
    const { history } = this.props;

    const rcmpFilter = <Filter fnOnType={this.handleNewFilter} />;

    const rcmpCardHeader = <CardHeader history={history} rcmpFilter={rcmpFilter} />;
    return rcmpCardHeader;
  };

  handleItemsPerPageChange = eventPagination => {
    const nItemsPerPage = parseInt(eventPagination.target.value, 10);
    this.setState({ nItemsPerPage, nCurrentPage: 1 }, this.fetchData);
  };

  render() {
    const { aPages, bLoading, nCurrentPage, nItemsPerPage, nTotalPages, nTotal } = this.state;
    const { getAlert } = this.props;

    const rcmpBreadcrumb = <Breadcrumb aPages={aPages} />;

    const rcmpCardHeader = this.generateCardHeader();

    const cPageTitle = intl.get('ReadingDocumentDelegates.title');

    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={cPageTitle}
        cImage={reportsImg}
        rcmpCardHeader={rcmpCardHeader}
        className="v2-report"
        cCurrentSideMenuOption={intl.get('Nav.report')}
      >
        {getAlert()}
        {this.renderReadingDocumentDelegates()}
        <PaginationMenu
          nCurrentPage={nCurrentPage}
          nItemsPerPage={nItemsPerPage}
          nTotalPages={nTotalPages}
          nTotal={nTotal}
          handlePageChange={this.handlePageChange}
          handleItemsPerPageChange={this.handleItemsPerPageChange} />
      </Page>
    );
  }
}

export default ReadingDocumentDelegates;
