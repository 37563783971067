import queryString from 'query-string';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import Select, { createFilter } from 'react-select';
import { Button, Input } from 'reactstrap';
import _ from 'lodash';
import getNewDocument from '../../actions/ActionDocument';
import loadTreeView from '../../actions/ActionTreeView';
import authorizedFolders from '../../actions/ActionAuthorizedFolders';
import { getRequest } from '../../actions/ActionRequest';
import { Alert } from '../../components/Alert';
import AltCheckbox from '../../components/AltCheckbox';
import Breadcrumb from '../../components/Breadcrumbs';
import LimitWidget from '../../components/LimitWidget';
import TreeViewLayer from '../../components/TreeViewLayer';
import Axios from '../../config/Axios';
import { can, checkModule } from '../../config/Permissions';
import PageClean from '../default-v2/PageClean';
import DateLimitation from '../../components/DateLimitation';
import checkTime from '../../components/CheckDate';
import './styles/document.scss';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

const oLocalStorage = new EncodedLocalStorage();
class Document extends Component {
  constructor(oProps) {
    super(oProps);
    this.alertWrapper = React.createRef();

    this.state = {
      bLoading: true,
      bIsSending: false,
      oLimits: [],
      oDocument: {
        fldr_nid: ((oProps.match || {}).params || {}).nFolderId || oProps.nFolderId,
        doc_nmaxprints: -1,
        doc_nmaxdownloadspdf: -1,
        doc_etermunitessay: 'D',
        doc_etermunitliberation: 'D',
        doc_etermunitreview: 'D',
        doc_eversiontype: 'A'
      },
      aRequests: {},
      aUsers: [],
      aSelectedUsers: [],
      aAttendedRequests: [],
      aPages: [
        {
          cName: 'modulo_documentos',
          cLink: '/folder'
        },
        {
          cName: 'Document.titulo',
          cLink: undefined
        }
      ],
      aFolders: [],
      aRequisites: [],
      aSelectedRequisites: [],
      oRequest: null,
      aTeams: [],
      aSelectedDistribution: []
    };
  }

  getTreeView = nRequestId => {
    if (nRequestId !== null && nRequestId !== undefined) {
      return loadTreeView().then(aFolders => this.setState({ aTreeViewFolders: aFolders }));
    }
    return authorizedFolders().then(aFolders => this.setState({ aTreeViewFolders: aFolders }));
  };

  componentDidMount = () => {
    let { nRequestId, bNewDocument, nVersionId, history } = this.props;
    checkModule(history, 'documents');

    if (bNewDocument !== null && bNewDocument !== undefined && bNewDocument) {
      Promise.all([
        this.getTreeView(),
        getNewDocument().then(oData => this.prepareNewDocument(oData)),
        nRequestId !== null && nRequestId !== undefined
          ? getRequest(nRequestId).then(oResponse => {
            this.setState({
              oRequest: oResponse.data.oRequest
            });
          })
          : ''
      ])
        .then(() => {
          const { oDocument, oRequest, aUsers } = this.state;
          let oResponsible = this.getTheUser(aUsers);
          oDocument['user_nid_responsible'] = oResponsible.user_nid;
          if (oRequest) {
            oDocument.doc_ctitle = oRequest.rqst_ctitle;
            oDocument.ver_cjustification = oRequest.rqst_cjustification;
          }
          this.setState({ oDocument, bLoading: false });
        })
        .catch(oError => {
          this.setState({
            rcmpAlertMsg: (
              <Alert type="error" isOpen onCloseAlert={() => { }}>
                {oError.response.data.cMessage}
              </Alert>
            ),
            bLoading: false
          });
        });
    } else {
      Promise.all([
        this.getTreeView(),
        getNewDocument().then(oData => this.prepareNewDocument(oData)),
        Axios.get(`/document/${nVersionId}`).then(oResponse => {
          const oSafeDistribution = (oResponse.data.aSelectedDistribution || []).filter(oTeam =>
            oTeam && oTeam.team_cname && oTeam.team_nid
          ).map(oTeam => ({
            label: oTeam.team_cname,
            value: oTeam.team_nid
          }));

          this.setState({
            oCurrentDocData: { ...oResponse.data },
            oDocument: oResponse.data.oDocument,
            aSelectedDistribution: oSafeDistribution
          });
        })
      ])
        .then(() => {
          this.prepareEditDocument();
          this.setState({ bLoading: false });
        })
        .catch(oError => {
          this.setState({
            rcmpAlertMsg: (
              <Alert type="error" isOpen onCloseAlert={() => { }}>
                {oError.response.data.cMessage}
              </Alert>
            ),
            bLoading: false
          });
        });
    }
  };

  prepareNewDocument = oData => {
    const { aRequests, aUsers, aRequisites, aTeams } = oData;
    const { oLocation, nRequisiteId } = this.props;
    const { aFolders } = this.state;
    let { nRequestId } = this.props;
    let aSelectedUsers = [];

    if (oLocation) {
      if (oLocation.state && oLocation.state.nRequestId) {
        nRequestId = Number(oLocation.state.nRequestId);
      } else {
        nRequestId = Number(queryString.parse(oLocation.search).nRequestId);
      }
    }

    const aAttendedRequests = [];
    const oRequest = aRequests.find(oObj => Number(oObj.rqst_nid) === nRequestId);

    if (oRequest) {
      oRequest.checked = true;
      aRequests[aRequests.indexOf(oRequest)] = oRequest;
      aAttendedRequests.push({ nReqId: nRequestId, dUpdated: oRequest.rqst_dupdated });
    }

    const { aSelectedRequisites } = this.state;

    const oSelectedRequisite = aRequisites.find(oReq => oReq.value === Number(nRequisiteId));
    if (oSelectedRequisite) {
      aSelectedRequisites.push(oSelectedRequisite);
    }

    Object.keys(aUsers).forEach(cKey => {
      aSelectedUsers.push({
        label: aUsers[cKey].user_cname,
        value: Number(aUsers[cKey].user_nid)
      });
    });

    const aNewTeams = aTeams.map(oTeam => ({
      label: oTeam.team_cname,
      value: oTeam.team_nid
    }));

    this.setState({
      aRequests: _.sortBy(aRequests, ['checked', 'rqst_nid']),
      aAttendedRequests,
      aRequisites,
      aUsers,
      aSelectedUsers,
      aTeams: aNewTeams
    });
  };

  prepareEditDocument = () => {
    const { oDocument, oCurrentDocData, aSelectedRequisites } = this.state;

    oDocument.user_nid_responsible = oCurrentDocData.oResponsible.user_nid;
    oDocument.user_nid_writer = oCurrentDocData.oWriter.user_nid;
    oDocument.user_nid_reviewer = oCurrentDocData.oReviewer.user_nid;
    oDocument.ver_cjustification = oCurrentDocData.oVersion.ver_cjustification;

    if (oCurrentDocData.aRequisites && oCurrentDocData.aRequisites.length > 0) {
      oCurrentDocData.aRequisites.map(oRequisite => {
        aSelectedRequisites.push(oRequisite);
      });
    }

    if (oCurrentDocData.oDocument.doc_eversiontype === 'M') {
      oDocument.ver_cnumber = oCurrentDocData.oVersion.ver_cnumber;
    }

    this.setState({
      aSelectedRequisites,
      oDocument
    });
  };

  inputChangeHandler = evt => {
    const { oDocument } = this.state;

    if (evt.target.type === 'checkbox') {
      oDocument[evt.target.name] = evt.target.checked;
      this.setState({
        oDocument
      });
    } else if (evt.target.value !== null) {
      oDocument[evt.target.name] = evt.target.value;
      this.setState({
        oDocument
      });
    }
  };

  inputChangeSelectHandler = () => {
    const { oDocument } = this.state;

    this.setState({
      oDocument
    });
  };

  arrayRemove = (aArray, cDel) => aArray.filter(cValue => cValue.nReqId !== cDel);

  inputChangeCheckBoxHandler = (evt, nReqId, cKey) => {
    let { aAttendedRequests } = this.state;
    const { aRequests } = this.state;

    if (evt.target.checked === true) {
      aAttendedRequests.push({ nReqId, dUpdated: aRequests[cKey].rqst_dupdated });
    } else {
      aAttendedRequests = this.arrayRemove(aAttendedRequests, nReqId);
    }

    aRequests[cKey].checked = evt.target.checked;

    this.setState({ aRequests });
    this.setState({ aAttendedRequests });
  };

  handleChangeCustom = (elSelectedOption, cType) => {
    this.setState({
      [cType]: elSelectedOption.map(oOpt => oOpt.value)
    });
  };

  createDocument = evt => {
    evt.preventDefault();
    const { oDocument, aAttendedRequests, aSelectedRequisites, oCurrentDocData, aSelectedDistribution } = this.state;
    const { history, onSuccess, bNewDocument, onError } = this.props;

    if (!checkTime('D', oDocument.doc_ntermessay, '2100-12-31')) {
      this.setState(
        {
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {intl.getHTML('warning_date_number', { cField: intl.get('Document.term_essay') })}
            </Alert>
          )
        },
        () => this.alertWrapper?.current?.scrollIntoView()
      );
      return;
    }
    if (!checkTime('D', oDocument.doc_ntermreview, '2100-12-31')) {
      this.setState(
        {
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {intl.getHTML('warning_date_number', { cField: intl.get('Document.prazo_revisao') })}
            </Alert>
          )
        },
        () => this.alertWrapper?.current?.scrollIntoView()
      );
      return;
    }
    if (!checkTime('D', oDocument.doc_ntermliberation, '2100-12-31')) {
      this.setState(
        {
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {intl.getHTML('warning_date_number', { cField: intl.get('Document.prazo_liberacao') })}
            </Alert>
          )
        },
        () => this.alertWrapper?.current?.scrollIntoView()
      );
      return;
    }

    this.setState({ bIsSending: true });

    if (bNewDocument && bNewDocument !== undefined && bNewDocument) {
      Axios.post('document', {
        oDocument,
        aAttendedRequests,
        aSelectedRequisites: aSelectedRequisites.map(oReq => oReq.value),
        aSelectedDistribution
      })
        .then(oResponse => {
          if (oResponse.data.pathname) {
            history.push({
              pathname: oResponse.data.ver_nid ? `/document/editor/${oResponse.data.ver_nid}` : '/',
              state: { aAlertMessages: { cType: 'success', cMsg: oResponse.data.cMessage } }
            });
          } else if (onSuccess) {
            onSuccess(intl.get('NewVersion.enviado_redacao'));
          } else {
            history.push({
              pathname: history.pathname,
              state: { aAlertMessages: { cType: 'success', cMsg: intl.get('NewVersion.enviado_redacao') } }
            });
          }
        })
        .catch(oError => {
          let cMessage = '';

          if (oError.response.data.cMessage != null) cMessage = oError.response.data.cMessage;
          else cMessage = oError.aMsgErrors;

          this.setState(
            {
              rcmpAlertMsg: (
                <Alert type="error" isOpen onCloseAlert={() => { }}>
                  {cMessage}
                </Alert>
              ),
              bLoading: false,
              bIsSending: false
            },
            () => this.alertWrapper?.current?.scrollIntoView()
          );
        });
    } else {
      Axios.put('version/update-document', {
        nVersionId: oCurrentDocData.oVersion.ver_nid,
        oDocument,
        aSelectedRequisites: aSelectedRequisites.map(oReq => oReq.value),
        aSelectedDistribution: aSelectedDistribution.map(oDistribution =>
          oDistribution.value ? oDistribution.value : oDistribution
        ),
        aAttendedRequests
      })
        .then(oResponse => {
          onSuccess(oResponse.data.cMessage);
        })
        .catch(oError => {
          let cMessage = '';

          if (oError.response.data.cMessage != null) cMessage = oError.response.data.cMessage;
          else cMessage = oError.aMsgErrors;

          onError(cMessage);
        });
    }
  };

  handleChangeRequisites = (aValue, { cAction, oRemovedValue }) => {
    let aValueRequisites = aValue;
    const { aSelectedRequisites } = this.state;
    const { nRequisiteId } = this.props;

    switch (cAction) {
      case 'remove-value':
      case 'pop-value':
        if (oRemovedValue.value === Number(nRequisiteId)) {
          return;
        }
        break;
      case 'clear':
        aValueRequisites = aSelectedRequisites.filter(oValue => oValue.value === Number(nRequisiteId));
        break;
      default:
    }

    this.setState({
      aSelectedRequisites: aValueRequisites
    });
  };

  handleChangeDocument = (elSelectedOption, cIndex) => {
    const { oDocument } = this.state;

    if (elSelectedOption !== null) oDocument[cIndex] = elSelectedOption.value;
    else oDocument[cIndex] = null;

    this.setState({
      oDocument
    });
  };

  renderUsers = aUsers => {
    const rcmpUsers = aUsers.map(oUser => (
      <option key={oUser.user_nid} value={oUser.user_nid}>
        {oUser.user_cname}
      </option>
    ));
    return <>{rcmpUsers}</>;
  };

  getTheUser = aUsers => {
    let oResponsible = null;
    aUsers.map(oUser => {
      if (oUser.user_nid == oLocalStorage.get('nUserId')) {
        oResponsible = oUser;
      }
    });

    return oResponsible;
  };

  renderResponsible = aUsers => {
    let oResponsible = null;
    aUsers.map(oUser => {
      if (oUser.value == oLocalStorage.get('nUserId')) {
        oResponsible = oUser;
      }
    });

    return [oResponsible];
  };

  renderRequest() {
    const { aRequests } = this.state;
    const { nRequestId } = this.props;

    const rcmpRequests = Object.keys(aRequests).map(cKey => (
      <li key={cKey}>
        <AltCheckbox
          key={aRequests[cKey].rqst_nid}
          title={intl.get('Document.aceitar_solicitacao')}
          className="split"
          checked={aRequests[cKey].checked === undefined ? false : aRequests[cKey].checked}
          value={aRequests[cKey].checked}
          id={cKey}
          disabled={nRequestId && aRequests[cKey].rqst_nid == nRequestId ? true : false}
          name="rqst_nid[]"
          label={aRequests[cKey].rqst_ctitle}
          onChange={evt => this.inputChangeCheckBoxHandler(evt, aRequests[cKey].rqst_nid, cKey)}
        />
      </li>
    ));

    return (
      <div className="request-wrapper">
        <label className="split1">
          {intl.get('Document.solicitacoes_atendidas')}
          <ul>{rcmpRequests}</ul>
        </label>
      </div>
    );
  }

  render() {
    const {
      oDocument,
      aUsers,
      bIsSending,
      aRequests,
      aPages,
      aTreeViewFolders,
      bLoading,
      oLimits,
      aRequisites,
      aSelectedRequisites,
      rcmpAlertMsg,
      aSelectedUsers,
      aTeams,
      aSelectedDistribution
    } = this.state;
    const { closeModal, history } = this.props;
    const cStringify = oOption => oOption.cLabel;
    const oFilterOption = createFilter({ bIgnoreCase: false, cStringify });
    const cModule = 'documents';

    return (
      <PageClean id="document-on" loading={bLoading ? 1 : 0} className="document">
        <h1>{intl.get('Document.titulo')}</h1>
        {!closeModal && <Breadcrumb aPages={aPages} />}
        <div ref={this.alertWrapper} style={{ paddingTop: 5 }}>
          {rcmpAlertMsg}
        </div>
        <LimitWidget oLimits={oLimits} />

        <form className="form" onSubmit={this.createDocument}>
          <label className="colspan">
            {intl.get('Document.campo_titulo')} <span className="required">*</span>
            <Input
              type="text"
              id="doc_ctitle"
              name="doc_ctitle"
              maxLength="100"
              required
              onChange={evt => this.inputChangeHandler.call(this, evt)}
              value={oDocument.doc_ctitle || ''}
            />
          </label>

          <label className="split2" htmlFor="tree-view">
            {intl.get('Document.pasta')} <span className="required">*</span>
            <TreeViewLayer
              oFolderTree={aTreeViewFolders}
              fnSelectFolder={this.inputChangeHandler}
              nFolderId={oDocument.fldr_nid}
              className="modal"
              cHeight="180px"
            />
          </label>

          <label className="split2">
            {intl.get('Nav.normas')} <span className='norm-smalltext'>({intl.get('Document.find_and_add')})</span>

            <Select
              onChange={this.handleChangeRequisites}
              value={aSelectedRequisites}
              className="selectCustom"
              classNamePrefix="react-select"
              noOptionsMessage={() => intl.get('Document.norma_nao_encontrada')}
              isMulti
              options={aRequisites}
              placeholder=""
            />
          </label>

          <label className="split1 colspan">
            {intl.get('Document.campo_justificativa')} <span className="required">*</span>
            <Input
              type="textarea"
              id="ver_cjustification"
              name="ver_cjustification"
              maxLength="255"
              style={{ height: '80px' }}
              required={true} // Validação de required no backend. => required=true buga no Firefox, abre tela grifando erro.
              onChange={evtChange => this.inputChangeHandler.call(this, evtChange)}
              value={oDocument.ver_cjustification || ''}
            />
          </label>

          <div className="row-responsible-title">
            <label className="label-responsible">
              {intl.get('Document.responsibles')}<span className="required">*</span>
            </label>
            <label className="label-term">
              {intl.get('Document.term')} ({intl.get('Document.days')})<span className="required">*</span>
            </label>
          </div>

          {can('admin', cModule) && (
            <div className="row-responsible">
              <div className="column-user-image">
                <i className="icon-editing" title={intl.get("Status.em-redacao")} />
                <div className="icon-vertical-line">&nbsp;</div>
              </div>
              <div className="column-user-responsible">
                <label>
                  {intl.get('Status.redaction')} <span className="required">*</span>
                  <Select
                    className="selectCustom"
                    classNamePrefix="react-select"
                    options={aSelectedUsers}
                    placeholder={intl.get('Document.writer_select')}
                    value={
                      oDocument.user_nid_writer ?
                        aSelectedUsers.filter(oUserItem => {
                          return oDocument.user_nid_writer === oUserItem.value
                        })
                        : ''
                    }
                    maxMenuHeight={150}
                    onChange={elSelectedOption => this.handleChangeDocument(elSelectedOption, 'user_nid_writer')}
                    required
                  />
                </label>
              </div>
              <div className="column-user-term">
                <DateLimitation
                  cId="doc_ntermessay"
                  cName="doc_ntermessay"
                  bIsRequired
                  nMin={0}
                  fnHandleChange={this.inputChangeHandler}
                  cValue={String(oDocument.doc_ntermessay) || ''}
                  cDiffTime={'D'}
                />
              </div>
            </div>
          )}

          <div className="row-responsible">
            <div className="column-user-image">
              <i className="icon-comment" title={intl.get("Status.em-revisao")} />
              <div className="icon-vertical-line">&nbsp;</div>
            </div>
            <div className="column-user-responsible">
              <label>
                {intl.get('Status.review_capitalized')} <span className="required">*</span>
                <Select
                  className="selectCustom"
                  classNamePrefix="react-select"
                  options={aSelectedUsers}
                  placeholder={intl.get('Document.selecione_revisor')}
                  value={
                    oDocument.user_nid_reviewer ?
                      aSelectedUsers.filter(oUserItem => {
                        return oDocument.user_nid_reviewer === oUserItem.value
                      })
                      : ''
                  }
                  maxMenuHeight={150}
                  onChange={elSelectedOption => this.handleChangeDocument(elSelectedOption, 'user_nid_reviewer')}
                  required
                />
              </label>
            </div>
            <div className="column-user-term">
              <DateLimitation
                cId="doc_ntermreview"
                cName="doc_ntermreview"
                bIsRequired
                nMin={0}
                fnHandleChange={this.inputChangeHandler}
                cValue={String(oDocument.doc_ntermreview) || ''}
                cDiffTime={'D'}
              />
            </div>
          </div>

          {can('admin', cModule) && (
            <div className="row-responsible">
              <div className="column-user-image">
                <i className="icon-published" title={intl.get("Status.aguardando-publicacao")} />
              </div>
              <div className="column-user-responsible">
                <label>
                  {intl.get('Status.publishing_capitalized')} <span className="required">*</span>
                  <Select
                    className="selectCustom"
                    classNamePrefix="react-select"
                    options={aSelectedUsers}
                    placeholder={intl.get('Document.responsible_select')}
                    value={
                      oDocument.user_nid_responsible ?
                        aSelectedUsers.filter(oUserItem => {
                          return oDocument.user_nid_responsible === oUserItem.value
                        })
                        : ''
                    }
                    maxMenuHeight={150}
                    onChange={elSelectedOption => this.handleChangeDocument(elSelectedOption, 'user_nid_responsible')}
                    required
                  />
                </label>
              </div>
              <div className="column-user-term">
                <DateLimitation
                  cId="doc_ntermliberation"
                  cName="doc_ntermliberation"
                  bIsRequired
                  nMin={0}
                  fnHandleChange={this.inputChangeHandler}
                  cValue={String(oDocument.doc_ntermliberation) || ''}
                  cDiffTime={'D'}
                />
              </div>
            </div>
          )}

          <label className="split2">
            {intl.get('Document.versionamento')}
            <Input
              type="select"
              id="doc_eversiontype"
              name="doc_eversiontype"
              onChange={evt => this.inputChangeHandler.call(this, evt)}
              value={oDocument.doc_eversiontype || ''}
            >
              <option value="A">{intl.get('Document.automatico')}</option>
              <option value="M">{intl.get('Document.manual')}</option>
            </Input>
            <small>{intl.get('DocumentEdit.versionamento_atencao')}</small>
          </label>

          {oDocument.doc_eversiontype === 'M' && (
            <label className="split2" id="version-manual">
              {intl.get('Document.versao')}
              <span className="required">*</span>
              <br></br>
              <Input
                type="text"
                id="ver_cnumber"
                name="ver_cnumber"
                maxLength="10"
                required
                onChange={evt => this.inputChangeHandler.call(this, evt)}
                value={oDocument.ver_cnumber || ''}
              />
            </label>
          )}

          <label className="split2">
            <span>{intl.get('Document.distribution')}</span>
            <Select
              className="selectCustom"
              classNamePrefix="react-select"
              noOptionsMessage={() => intl.get('Document.team_not_found')}
              isMulti
              defaultValue={aSelectedDistribution}
              options={aTeams}
              onChange={elSelectedOption => this.handleChangeCustom(elSelectedOption, 'aSelectedDistribution')}
              placeholder={intl.get('Document.select_teams')}
              maxMenuHeight={100}
            />
          </label>

          {aRequests.length ? this.renderRequest() : ''}

          <div className="button-stack">
            {closeModal ? (
              <Button type="button" className="btn alternate back" onClick={() => closeModal()}>
                {intl.get('cancel')}
              </Button>
            ) : (
              <Button type="button" className="btn alternate alert" onClick={() => history.goBack()}>
                {intl.get('cancel')}
              </Button>
            )}
            <Button type="submit" disabled={bIsSending}>
              {intl.get('salvar')}
            </Button>
          </div>
        </form>
      </PageClean>
    );
  }
}

export default Document;
