import React, { Component } from 'react';
import intl from 'react-intl-universal';
import IconStatus from '../../components/IconStatus';
import DocumentResponsibleUserAction from './DocumentResponsibleUserAction';
import moment from 'moment';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

class MyDocumentComponent extends Component {
  constructor(oProps) {
    super(oProps);
    this.state = {
      bIsVisible: true,
      nDocmentId: 0
    };
  }

  renderMenu = oDocument => {
    const { fnRenderDocumentMenu } = this.props;

    fnRenderDocumentMenu(oDocument);
  };

  renderSubMenu = oItem => {
    const { fnDeleteModal, fnEditModal } = this.props;
    return (
      <div
        onMouseOver={evt => {
          evt.stopPropagation();
          this.setState({ bIsVisible: false, nDocmentId: oItem.ver_nid });
        }}
        onMouseLeave={evt => {
          evt.stopPropagation();
          this.setState({ bIsVisible: true, nDocmentId: oItem.ver_nid });
        }}
      >
        <span className="submenu">
          <>
            <a
              title={intl.get('edit', { cType: '' })}
              className="insidespan"
              onClick={evt => {
                evt.stopPropagation();
                fnEditModal(oItem);
              }}
            >
              <i className="icon icon-edit" />
            </a>
            <a
              title={intl.get('excluir', { tipo: '' })}
              className="insidespan"
              onClick={evt => {
                evt.stopPropagation();
                fnDeleteModal(evt, oItem, oItem);
              }}
            >
              <i className="icon icon-bin" />
            </a>
          </>
        </span>
      </div>
    );
  };

  render() {
    const { aResponsibleDocuments, onClick, onClickIcon, bIsMyAccountPage } = this.props;
    const { bIsVisible, nDocmentId } = this.state;
    if (aResponsibleDocuments.length <= 0) {
      if (bIsMyAccountPage) return <h3>{intl.get('MyDocument.nenhum_documento')}</h3>;
      else return '';
    }

    if (bIsMyAccountPage) {
      return (
        <ul className="dashboard-list table-4">
          <li className="thead no-pointer">
            <b>{intl.get('titulo')}</b>
            <b>{intl.get('usuario')}</b>
            <b>{intl.get('acao')}</b>
            <b>{intl.get('expire_date')}</b>
          </li>
          {aResponsibleDocuments.map(oItem => (
            <li
              key={oItem.ver_nid}
              className="request"
              onClick={() => onClick(oItem.ver_nid)}
              onKeyPress={() => onClick(oItem.ver_nid)}
            >
              <h3 style={{ visibility: !bIsVisible && nDocmentId == oItem.ver_nid ? 'hidden' : 'visible' }}>
                <span style={{ cursor: 'pointer' }} title={intl.get('MyAccount.ver_documento')}>
                  {oItem.doc_ctitle}
                </span>
              </h3>
              <DocumentResponsibleUserAction
                cVersionStatus={oItem.stat_cslugname}
                oDocument={oItem}
                aDataDiff={oItem}
                onClick={onClickIcon}
              />
              {this.renderSubMenu(oItem)}
              <p className="status">{oItem.cDataDiff}</p>
            </li>
          ))}
        </ul>
      );
    }
    const oLocalStorage = new EncodedLocalStorage();
    return aResponsibleDocuments.map(oItem => (
      <tr
        onClick={() => onClick(oItem)}
        onKeyPress={() => onClick(oItem)}
        className="row-pending-responsible-edits"
        key={oItem.ver_nid}
      >
        <td title={oItem.doc_ctitle}>
          <span style={{ visibility: !bIsVisible && nDocmentId == oItem.ver_nid ? 'hidden' : 'visible' }}>
            {oItem.doc_ctitle && oItem.doc_ctitle.trim() ? oItem.doc_ctitle : 'Documento sem título'}
          </span>
          {this.renderSubMenu(oItem)}
        </td>
        <td title={oItem.user_cname}>
          <span>{oItem.user_cname}</span>
        </td>
        <td className="action">
          <IconStatus cStatus={oItem.stat_cslugname} onClick={evt => { }} />
        </td>
        <td>
          {oItem.ver_cenvelope_id
            ? oItem.oSigners.length > 0
              ? oItem.oSigners.every(item => item.ds_cstatus === 'completed')
                ? intl.get('DocumentPublish.assinado')
                : intl.get('DocumentPublish.esperando_assinatura') +
                ' ' +
                oItem.oSigners.filter(item => item.ds_cstatus === 'completed').length +
                '/' +
                oItem.oSigners.length
              : intl.get('DocumentPublish.esperando_docusign')
            : intl.get('DocumentPublish.aguardando_publicação')}
        </td>
        <td>
          {moment
            .parseZone(oItem.cDataDiffStandard)
            .tz(oLocalStorage.get('cTimezone'))
            .format('LL')}
        </td>
      </tr>
    ));
  }
}

MyDocumentComponent.defaultProps = {
  bIsMyAccountPage: true
};

export default MyDocumentComponent;
